export interface DrfResponse<T = any> {
  count: number;
  next: string;
  previous: string;
  results: Array<T>;
}

export interface CeleryTaskStatus {
  _state: CeleryImportState;

  [key: string]: any;
}

export enum CeleryImportState {
  Pending = 'PENDING',
  Progress = 'PROGRESS',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}
