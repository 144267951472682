import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnChanges {
  @Input() pdfFile;
  @Input() showDownload = false;
  @Output() downloaded = new EventEmitter<void>();
  @Output() clickPreview = new EventEmitter<void>();

  isPdfLoading: string;
  themeList: ThemeList;
  currentDocumentPage: number;
  totalDocumentPage: number;
  isDocumentLoadingPage: boolean;
  httpHeaders;

  constructor(
    public themeService: ThemeService,
    private authenticationService: AuthenticationService,
  ) {
    this.httpHeaders = this.authenticationService.httpHeader;
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnChanges(changes: SimpleChanges | any): void {
    if (changes.pdfFile) {
      this.httpHeaders = this.authenticationService.httpHeader;
    }
  }

  onLoadPdfComplete(pdf: PDFDocumentProxy): void {
    this.currentDocumentPage = 1;
    this.totalDocumentPage = pdf.numPages;
    this.isDocumentLoadingPage = false;
  }

  get documentPages(): number[] {
    return Array(this.totalDocumentPage)
      .fill(1)
      .map((value, index) => index + 1);
  }

  changeDocumentPage(page: number): void {
    this.currentDocumentPage = +page;
  }

  changeDocumentPageBack(): void {
    if (
      !this.isDocumentLoadingPage &&
      this.currentDocumentPage - 1 >= 1
    ) {
      this.currentDocumentPage--;
    }
  }

  changeDocumentPageNext(): void {
    if (
      !this.isDocumentLoadingPage &&
      this.currentDocumentPage + 1 <= this.totalDocumentPage
    ) {
      this.currentDocumentPage++;
    }
  }
}
