import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import { MarkerIdentity } from '../../modules/memos/components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';
import { Injectable } from '@angular/core';
import { CloseTool, OpenTool } from './checkbox.actions';

export class CheckboxStateModel {
  public selected: MarkerIdentity;
}
const DRAWING_STATE_TOKEN = new StateToken<CheckboxStateModel>(
  'checkbox',
);

@State<CheckboxStateModel>({
  name: DRAWING_STATE_TOKEN,
  defaults: {
    selected: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class CheckboxState {
  constructor() {}

  @Selector()
  static selected(state: CheckboxStateModel) {
    return state.selected;
  }

  @Action(OpenTool)
  openTool(ctx: StateContext<CheckboxStateModel>, action: OpenTool) {
    ctx.patchState({
      selected: action.selected,
    });
  }

  @Action(CloseTool)
  CloseTool(
    ctx: StateContext<CheckboxStateModel>,
    action: CloseTool,
  ) {
    ctx.patchState({
      selected: null,
    });
  }
}
