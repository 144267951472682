import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarService } from '../navbar/navbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  hasPermission = false;
  recall = false;
  approval;
  message = this.hasPermission
    ? `Sorry, but you don't have permission to access this page.`
    : `Sorry, we're unable to find the page you're looking for.`;

  constructor(
    private activateRoute: ActivatedRoute,
    private navbar: NavbarService,
    private router: Router,
    public translate: TranslateService,
  ) {
    this.navbar.setActiveSidebar(false);
    this.activateRoute.queryParams.subscribe((res) => {
      this.hasPermission = !!res.permission;
      this.recall = !!res.recall;
      this.approval = res.approval;
    });
  }

  goToLogin() {
    if (this.approval) {
      this.router.navigate(['/login'], {
        queryParams: {
          approval: this.approval,
          lang: this.translate.currentLang,
        },
      });
      return;
    }

    this.router.navigate(['/login']);
  }
}
