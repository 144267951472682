import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/http/api.service';
import { asapScheduler, Observable, of } from 'rxjs';
import {
  MutedNotification,
  NotificationChannel,
  NotificationList,
} from '../../../shared/service/notification.service';
import { Store } from '@ngxs/store';
import { map, take } from 'rxjs/operators';
import { PersonalCertificateStatus } from '../../certificate/shared/certificate.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  certificateStatusCache = {};
  profileDetail = this.store.selectSnapshot((state) => state.auth);

  constructor(private http: ApiService, private store: Store) {}

  abbreviateFullName(name: string, splitter = ' '): string {
    if (name == null || name === '') {
      return name;
    }
    name = name.trim();
    const apartNames = name.split(splitter);
    const abbvNames = apartNames.map((apartName) => apartName[0]);
    return abbvNames.join('.') + '.';
  }

  setPassword(data) {
    return this.http.put(`/api/change-password/`, data);
  }

  // Delegate
  getDelegate() {
    return this.http.get(`/api/user-delegate/`);
  }

  createDelegate(data) {
    return this.http.post(`/api/user-delegate/`, data);
  }

  updateDelegate(id, data) {
    return this.http.patch(`/api/user-delegate/${id}/`, data);
  }

  deleteDelegate(id) {
    return this.http.delete(`/api/user-delegate/${id}/`);
  }

  loadInputData() {
    const id = this.store.selectSnapshot(
      (state) => state.auth.person_id,
    );
    return this.http.get(`/api/people/${id}/`);
  }

  loadCertificateStatus(
    id = null,
    canUseCache = false,
    params?: { [k: string]: string },
  ): Observable<PersonalCertificateStatus> {
    let cacheGroupKey: string;
    if (id == null)
      id = this.store.selectSnapshot((state) => state.auth.person_id);

    /** In case, the params is valid. To prepare cacheGroupKey for accession  */
    if (params) {
      cacheGroupKey = Object.keys(params)
        .map((key) => `${key}_${params[key]}`)
        .join('__');
    }
    const cacheValue = params
      ? this.certificateStatusCache[cacheGroupKey]?.[id]
      : this.certificateStatusCache[id];
    // return data from cache to prevent calling API too much.
    if (canUseCache && cacheValue) {
      return new Observable((subscriber) => {
        asapScheduler.schedule(() => {
          subscriber.next(cacheValue);
        });
      }).pipe(take<PersonalCertificateStatus>(1));
    }

    return this.http
      .get<PersonalCertificateStatus>(
        `/api/people/${id}/certificate-status/`,
        params,
      )
      .pipe(
        map((data) => {
          // store certificate status into cache.
          const cache = this.certificateStatusCache;
          if (params) {
            if (
              cache[cacheGroupKey] === null ||
              typeof cache[cacheGroupKey] !== 'object'
            ) {
              cache[cacheGroupKey] = {};
            }
            cache[cacheGroupKey][id] = data;
          } else {
            cache[id] = data;
          }
          return data;
        }),
      );
  }

  updateSelf(id, data) {
    return this.http.patch(`/api/people/${id}/update-self/`, data);
  }

  // OTP
  OpenOtp(data) {
    return this.http.post(`/api/open-otp/`, data);
  }

  CloseOtp(data) {
    return this.http.post(`/api/close-otp/`, data);
  }

  verifyOtp(data) {
    return this.http.post(`/api/verify-otp/`, data);
  }

  // Setting notifications
  getLinkLineCODIUM() {
    return this.http.get(`/api/line/codium/`);
  }

  getLinkLineUser() {
    return this.http.get(`/api/line/user/`);
  }

  settingEmail(id, data) {
    return this.http.patch(`/api/mail-notifications/${id}/`, data);
  }

  updatePreferredLanguage(id, language) {
    return this.http.patch(
      `/api/people/${id}/update-preferred-language/`,
      { preferred_language: language },
    );
  }

  updateMutedNotification(id: number, data) {
    return this.http.patch(`/api/muted-notifications/${id}/`, data);
  }

  getNotificationAction(params?): Observable<NotificationList[]> {
    return this.http.get(`/api/notification-actions/`, params);
  }

  getNotificationChannel(params?): Observable<NotificationChannel[]> {
    return this.http.get(`/api/notification-channels/`, params);
  }

  getMutedNotification(params?): Observable<MutedNotification[]> {
    return this.http.get(`/api/muted-notifications/`, params);
  }

  profile() {
    this.profileDetail = this.store.selectSnapshot(
      (state) => state.auth,
    );
  }

  updateAuthenticateOTPMode(id, value) {
    return this.http.patch(`/api/people/${id}/update-self/`, {
      always_use_otp: value,
    });
  }

  updateExpiryDate(id, data) {
    return this.http.patch(
      `/api/people/${id}/update-default-memo-expire-days/`,
      data,
    );
  }

  getPasswordPolicy() {
    return this.http.get(`/api/pw-policy/`);
  }
}
