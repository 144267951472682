<div class="row" (window:resize)="onResize($event)">
  <div class="col-lg-6 col-md-12 col-12 px-0 bg-image"
    [ngStyle]="{'background-image': 'url('+(themeList?.bg_login_image| secure | async)+ ')'}">
  </div>
  <div class="col-lg-6 col-md-12 col-12 px-0">
    <div class="login-frame bg overflow-y-auto" *ngIf="currentTime" [ngStyle]="{'background': 'linear-gradient(277.33deg, '+themeList?.bg_login_second_color+', '+themeList?.bg_login_color+' 130%)',
            'color': themeList?.text_login_color}">
      <div class="translate">
        <div class="box-translate p-1 pointer">
          <div (click)="changeLanguage('th')" *ngIf="this.language === 'en'">
            <svg-icon src="assets/images/icons/usa.svg" [svgStyle]="{ 'height.px': 30, 'width.px': 30}">
            </svg-icon>
            <span class="pl-2">EN</span>
          </div>
          <div (click)="changeLanguage('en')" *ngIf="this.language === 'th'">
            <svg-icon src="assets/images/icons/thai.svg" [svgStyle]="{ 'height.px': 30, 'width.px': 30}">
            </svg-icon>
            <span class="pl-2">TH</span>
          </div>
        </div>
      </div>
      <div class="set-center-box">
        <div class="mx-auto mt-auto mb-0 welcome-text">
          <div class="fa-logo m-10">
            <img [src]="themeList?.display_login | secure | async" width="auto" height="100">
            <div class="text-center mt-0 mt-md-3">
              <h1 class="m-b-15 mt-md-0 mt-3 custom-text" [ngStyle]="{'color': themeList?.text_login_color}">
                AXONS SIGN</h1>
              <h5 class="m-b-15 custom-text" *ngIf="!loginMode" [ngStyle]="{'color': themeList?.text_login_color}">
                {{'LOGIN.FORGOT-PASSWORD' | translate}}</h5>
              <h5 class="title-content" [ngStyle]="{'color': themeList?.text_login_color}" *ngIf="loginMode">
                {{"LOGIN.Please Login to continue"|translate}}</h5>
              <h6 [ngStyle]="{'color': themeList?.text_login_color}" *ngIf="!loginMode && !forgotPasswordSuccess">
                {{textList|translate}}</h6>

            </div>
          </div>
        </div>
        <div class="login-box">
          <div class="login-form config-width-height"
            [ngClass]="[!loginMode? 'forgot-width':'',forgotPasswordSuccess? 'custom-padding':'']">

            <!--   ADFS Login   -->
            <div *ngIf="adfsLoginMode && !forgotPasswordMode">
              <input   type="text" autofocus autocomplete="off" class="border-almost-white  border-none  custom-input"
                name="usernameFortesting" [(ngModel)]="email" (keyup.enter)="adfsLogin()"
                placeholder="{{'LOGIN.EMAIL' | translate}}" (focus)="OnFocusField()">
              <div class="forget-password-description text-right mt-2">
                <span (click)="toggleForgetPassword()" class="custom-text-blue pointer" [ngStyle]="{'color': themeList?.text_login_color, 'border-bottom': '1px solid',
                      'border-color': themeList?.text_login_color}">
                  {{'LOGIN.FORGOT-PASSWORD' | translate}}?
                </span>
              </div>
              <button class="btn login-bt w-100 custom-text-white mt-3 p-btn" [ngStyle]="{
                'background': themeList?.button_login_color,
                'color': themeList?.text_button_login_color
              }" (click)="adfsLogin()">
                {{ 'MEMOS.NEXT' | translate }}
              </button>
            </div>

            <!--  Normal Login    -->
            <ng-container *ngIf="!adfsLoginMode">
              <!--    Login    -->
              <form [formGroup]="loginForm" *ngIf="loginMode" (ngSubmit)="onSubmit()">
                <div class="m-b-10">
                  <input type="text" autocomplete="off" class="border-almost-white  border-none custom-input"
                    name="usernameFortesting" placeholder="{{'LOGIN.USERNAME' | translate}}" formControlName="username"
                    (focus)="OnFocusField()">
                </div>
                <div class="m-b-10 mt-3">
                  <input type="password" autocomplete="off" class="pl-1 border-almost-white  border-none custom-input"
                    name="passwordFortesting" placeholder="{{'LOGIN.PASSWORD' | translate}}" formControlName="password">
                </div>
                <div class="forget-password-description text-right mt-2" *ngIf="loginMode">
                  <span (click)="toggleForgetPassword()" class="custom-text-blue pointer" [ngStyle]="{'color': themeList?.text_login_color, 'border-bottom': '1px solid',
                      'border-color': themeList?.text_login_color}">
                    {{'LOGIN.FORGOT-PASSWORD' | translate}}?
                  </span>
                </div>
                <button name="buttonForTesting"
                    class="btn login-bt w-100 mt-4 p-btn"
                    [ngStyle]="{
                      'background': themeList?.button_login_color,
                      'color': themeList?.text_button_login_color}" href="javascript:void(0)">
                  {{'LOGIN.LOGIN' | translate}}
                </button>

              </form>
            </ng-container>

            <!--   Forget Password     -->
            <ng-container *ngIf="forgotPasswordMode">
              <div *ngIf="!forgotPasswordSuccess">
                <div class="m-b-10">
                  <div class="mb-2">
                    <input type="text" autocomplete="off" class="border-almost-white outline-input custom-input"
                      name="email" placeholder="{{'LOGIN.EMAIL' | translate}}" [(ngModel)]="email"
                      (keyup.enter)="submitForgotPassword()">
                  </div>
                </div>

                <button name="buttonForTesting" class="mt-2 btn w-100" type="button" [ngStyle]="{
                      'background': themeList?.button_login_color,
                      'color': themeList?.text_button_login_color}" href="javascript:void(0)" [disabled]="false"
                  (click)="submitForgotPassword()">
                  {{'LOGIN.SEND' | translate}}
                </button>
                <div class="mt-3 pointer" (click)="gotoLogin()" [style.--c]="themeList?.header_table_color">
                  <span class="border-bottom change-mode" [ngStyle]="{'color': themeList?.text_login_color}">
                    {{'LOGIN.BACK-LOGIN' | translate}}
                  </span>
                </div>
              </div>
              <div *ngIf="forgotPasswordSuccess" class="forgot-box">
                <div class="text-forgot">{{"LOGIN.EMAIL-RESET-TEXT"|translate}}</div>
                <button class="btn p-btn mt-4" (click)="gotoLogin()" [ngStyle]="{
                  'background': themeList?.button_login_color,
                  'color': themeList?.text_button_login_color}">
                  {{'LOGIN.BACK-LOGIN' | translate}}
                </button>
              </div>
            </ng-container>
            <div class="mt-3 d-flex row" [style.--color]="themeList?.text_login_color"
              *ngIf="loginMode && url !== 'sign.axonstech.com'">
              <hr class="col-3" [ngStyle]="{'background': themeList?.text_login_color}">
              <span class="pt-1">{{"LOGIN.or"|translate}}</span>
              <hr class="col-3" [ngStyle]="{'background': themeList?.text_login_color}">
              <div class="col-12 mt-3" *ngIf="adfsLoginMode">
                <span class="change-mode pointer" (click)="adfsLoginMode = false;">{{"LOGIN.Login with local
                  account"|translate}}</span>
              </div>
              <div class="col-12 mt-3" *ngIf="!adfsLoginMode">
                <span class="change-mode pointer" (click)="adfsLoginMode = true;">{{"LOGIN.Login with CPF
                  e-mail"|translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>




      <ng-container *ngIf="newFeature; else oldFeature">
        <div class="">
          <div class="version" [ngStyle]="{'color': themeList?.text_login_color}">
            <div class="mt-3 position-support pl-3">
              <div class="pointer menu" (click)="redirectToExternalUrl('faq')">
                <svg-icon src="assets/svg/login/icon-faq.svg" [stretch]="true"
                  [svgStyle]="{'fill': themeList?.text_login_color, 'width.px': 18, 'height.px': 16}" class="mr-2">
                </svg-icon> {{"LOGIN.FAQ"|translate}}
              </div>
              <div class="ml-3 pointer menu" (click)="redirectToExternalUrl('feedback')">
                <svg-icon src="assets/svg/login/icon-chat.svg" [stretch]="true"
                  [svgStyle]="{'fill': themeList?.text_login_color, 'width.px': 18, 'height.px': 18}" class="mr-2">
                </svg-icon> {{"LOGIN.Feedback"|translate}}
              </div>
              <div class="ml-3 pointer" (click)="redirectToExternalUrl('help')">
                <svg-icon src="assets/svg/login/icon-help.svg"
                  [svgStyle]="{'fill': themeList?.text_login_color, 'width.px': 18, 'height.px': 18}" [stretch]="true"
                  class="mr-2">
                </svg-icon> {{"LOGIN.Help tips"|translate}}
              </div>
            </div>
            <div class="mx-0 my-2">v.{{version}}</div>
            <div class="pl-3"> © 2022 Power by AXONS. All Rights Reserved.</div>
            <div class="mx-auto row mt-3">
              <div (click)="redirectToExternalUrl('policy')"
                  class="pointer col-6 policy text-right pr-5">{{"LOGIN.Privacy Policy"|translate}}</div>
              <div (click)="redirectToInternalUrl('policy')"
                  class="pointer col-6 policy text-left pl-4">{{"LOGIN.Terms and Conditions"|translate}}</div>
            </div>
          </div>
        </div>

      </ng-container>

      <ng-template #oldFeature>
        <div class="version" [ngStyle]="{'color': themeList?.text_login_color}">
          <div class="mt-3 position-support">
            <div class="pointer menu" (click)="redirectToExternalUrl('faq')">
              <svg-icon src="assets/svg/login/chat.svg" [stretch]="true"
                [svgStyle]="{'fill': themeList?.text_login_color}" class="mr-2">
              </svg-icon> {{"LOGIN.FAQ"|translate}}
            </div>
            <div class="ml-3 pointer menu" (click)="redirectToExternalUrl('feedback')">
              <svg-icon src="assets/svg/login/bullhorn.svg"
                [svgStyle]="{'fill': themeList?.text_login_color, 'margin-top':'-5px'}" [stretch]="true" class="mr-2">
              </svg-icon> {{"LOGIN.Feedback"|translate}}
            </div>
            <div class="ml-3 pointer" (click)="redirectToExternalUrl('rating')">
              <svg-icon src="assets/svg/login/star.svg"
                [svgStyle]="{'fill': themeList?.text_login_color,'margin-top':'-5px'}" [stretch]="true" class="mr-2">
              </svg-icon> {{"LOGIN.Rating"|translate}}
            </div>
          </div>
          <div class="m-0">v.{{version}}</div>
        </div>
        <div class="text-right position-relative">
          <!--        <div class="position-bg-logo">-->
          <!--          <svg-icon src="assets/images/bg-logo.svg"-->
          <!--              [stretch]="true">-->
          <!--          </svg-icon>-->
          <!--        </div>-->
          <!--      </div>-->
        </div>
      </ng-template>

    </div>
    <div (click)="adfsLoginMode = false;" 
      style="display:none; position:fixed;background-color: rgba(255, 255, 255, 0.5);bottom:0;left:0;width:10px;height:10px;"
      id="53c8603b7ff140eea86d22e595d3c705">
    </div>
  </div>




  <app-modal-login #adfsCallbackModal>
  </app-modal-login>

  <app-modal-cloud-service #cloudServiceCallbackModal>
  </app-modal-cloud-service>

  <ng-template #wrongPasswordAttempt let-c="close" let-d="dismiss">
    <div class="modal-header border-bottom-0">
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="modal-body text-center wrong-password">
      <svg-icon src="assets/svg/lock-red.svg">
      </svg-icon>
      <ng-container *ngIf="remainingAttempts > 0">
        <p class="font-weight-bold mt-4 title">{{"AUTH.Your account will be locked" | translate}}</p>
        <p class="mt-4">{{"AUTH.Login with" | translate}} <span class="font-weight-bold">{{f.username.value}}</span></p>
        <p>{{"AUTH.will be locked due to multiple failed login attempts" | translate}}</p>
        <p class="text-red font-weight-bold">{{"AUTH.You’ll have" | translate}}
          {{remainingAttempts}}
          {{"AUTH.more attempt before your account is locked for 10 minutes"|translate}}
        </p>
        <p>{{"AUTH.or click forget your password to reset it."|translate}}</p>
        <button type="button" appSetTheme class="btn my-4 py-2" style="width: 85%" (click)="toggleForgetPassword()">
          {{'LOGIN.FORGOT-PASSWORD' | translate}}
        </button>
      </ng-container>
      <ng-container *ngIf="remainingAttempts <= 0">
        <p class="font-weight-bold mt-4 title">{{"AUTH.Your account has been locked"|translate}}</p>
        <p class="mt-4">{{"AUTH.Login with" | translate}} <span class="font-weight-bold">{{f.username.value}}</span></p>
        <p>{{"AUTH.has been temporarily locked"|translate}}</p>
        <p>{{"AUTH.due to multiple failed login attempts, Please try again later"|translate}}</p>
        <p *ngIf="currentLang === 'th'">{{"AUTH.Please try again later"|translate}}</p>
        <p>{{"AUTH.or click forget your password to reset it."|translate}}</p>
        <button type="button" appSetTheme class="btn my-4 py-2" style="width: 85%" (click)="toggleForgetPassword()">
          {{'LOGIN.FORGOT-PASSWORD' | translate}}
        </button>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #carouselBanner>
    <app-carousel-banner (closeModal)="closeModal()"></app-carousel-banner>
  </ng-template>