<!-- <div class="form-inline"> -->
<div class="form-group">
  <div class="input-group w-100">
    <input type="text"
        class="form-control date-picker box-s"
        placeholder="Select Period"
        ngbDatepicker
        #datepicker="ngbDatepicker"
        [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="2"
        [dayTemplate]="t"
        outsideDays="hidden"
        [startDate]="date.fromDate!"
        (click)="datepicker.open()"
        [value]="getShowDate()">
    <div class="input-group-append">
      <button class="btn bg-dark shadow-none"
          (click)="clearDate()"
          type="button"
          [disabled]="!date.fromDate && !date.toDate">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <ng-template #t
        let-date
        let-focused="focused">
        <span class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
    </ng-template>
  </div>
</div>
<!-- </div> -->