import { MarkerIdentity } from '../../modules/memos/components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';
import { CanvasWhiteboardUpdate } from 'ng2-canvas-whiteboard';

export class OpenDrawing {
  static readonly type = '[Drawing] Open Drawing';
  constructor(public selected: MarkerIdentity) {}
}

export class CloseDrawing {
  static readonly type = '[Drawing] Close Drawing';
}

export class SaveDrawing {
  static readonly type = '[Drawing] Save Drawing';
  constructor(
    public marker: MarkerIdentity,
    public url: string | ArrayBuffer,
    public blob: Blob,
    public updates: CanvasWhiteboardUpdate[],
  ) {}
}

export class UpdateDrawingOption {
  static readonly type = '[Drawing] Update Drawing Option';
  constructor(public field: string, public value: any) {}
}

export class ClearDrawing {
  static readonly type = '[Drawing] Clear Drawing';
  constructor(public marker: MarkerIdentity) {}
}

export class ClearStateDrawing {
  static readonly type = '[App] Clear State Drawing';
}
