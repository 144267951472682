<ng-template #confirmSuccess>
  <ng-container *ngIf="!isApprovalList; else confirmSuccessForMail">
    <div class="modal-body">
      <div class="row">
        <div class="col-12 text-center">
          <svg-icon src="assets/images/vector.svg"
              [svgStyle]="{ 'width.px': 90, 'height.px': 90 }"></svg-icon>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mt-5 font">
          {{textMessage}}
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="col-auto text-center">
        <button (click)="previousPage()"
            appSetTheme
            type="button"
            class="btn p-btn fontbtn"
            style="padding: 10px 15px;">
          {{ 'APPROVAL.CONTINUE-APPROVE' | translate }}
        </button>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="col-auto text-center mb-5">
        <div class="pointer"
            (click)="goToMemoDetail()"
            style="font-size: 13px; text-decoration: underline"
            [style.color]="themeList?.header_table_color">
          {{ 'APPROVAL.GO-TO-CHECK-DOCUMENT' | translate }}
        </div>
      </div>
    </div>
  </ng-container>

</ng-template>

<ng-template #confirmSuccessFromDashboard>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <svg-icon src="assets/images/vector.svg"
            [svgStyle]="{ 'width.px': 90, 'height.px': 90 }"></svg-icon>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-5 font">
        {{textMessage}}
      </div>
      <ng-container *ngIf="subTitile">
      <div class="col-12 text-center mt-2 font">
        {{subTitile}}
      </div>
      <div class="col-12 text-center mt-1 font">
        {{textTimeRemaining}}
      </div>
    </ng-container>
    </div>
  </div>

  <div class="col-12 mb-5">
    <div class="col-auto text-center">
      <button (click)="closeMessage()"
          [ngStyle]="{
          'border-color': themeList?.header_table_color,
          'color': themeList?.header_table_color}"
          type="button"
          class="btn p-btn fontbtn"
          style="padding: 10px 15px;">
        {{ 'MEMOS.CLOSE' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #confirmAlertMessage>
  <div class="modal-header">
    <h5>{{ 'APPROVAL.CONFIRM-APPROVAL' | translate }}</h5>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" 
  [style.padding-top]="'4rem'"
  >
    <div class="row">
      <div class="col-12 text-center pb f-16">
        {{
        'APPROVAL.ARE-YOU-SURE-YOU-WANT-TO-APPROVE-THIS-DOCUMENT'
        | translate
        }}
      </div>
    </div>
    <div class="row justify-content-center px-2">
      <button class="btn p-btn btn-outline-yale-blue mx-2"
          (click)="close()">
        {{ 'APPROVAL.CANCEL' | translate }}
      </button>

      <button class="btn p-btn mx-2"
          type="button"
          appSetTheme
          (click)="onConfirmClick()">
        {{ 'APPROVAL.CONFIRM' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #confirmSuccessForMail>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <svg-icon src="assets/images/vector.svg"
            [svgStyle]="{ 'width.px': 90, 'height.px': 90 }"></svg-icon>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-5 font">
        {{textMessage}}
      </div>
      <div class="col-12 text-center mt-2" 
          [style.color]="'#808080'">
        {{replaceWord}}
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="col-auto text-center">
      <button (click)="previousPage()"
          appSetTheme
          type="button"
          class="btn p-btn fontbtn"
          style="padding: 10px 15px;">
        {{ 'APPROVAL.CONTINUE-APPROVE' | translate }}
      </button>
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="col-auto text-center mb-5">
      <div class="pointer"
          (click)="goToApprovalDetail()"
          style="font-size: 13px; text-decoration: underline"
          [style.color]="themeList?.header_table_color">
        {{ 'APPROVAL.GO-TO-CHECK-DOCUMENT' | translate }}
      </div>
    </div>
  </div>
</ng-template>