import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'date',
  pure: false,
})
export class BuddhistDatePipe
  extends DatePipe
  implements PipeTransform
{
  constructor(private translate: TranslateService) {
    super(translate.currentLang);
  }

  transform(value: any, format?: any, lang?: any): any {
    if (!value) {
      let dt = dayjs.isDayjs(value) ? value : dayjs(value);
      if (this.translate.currentLang === 'th') {
        dt = dt.add(543, 'years');
      }
      value = dt.toDate();
    }
    let langues = null;
    if (format === 'mm:ss') {
      langues = 'en';
    }
    langues = langues ? langues : this.translate.currentLang;
    return super.transform(value, format, undefined, langues);
  }
}
