import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { featureFlag } from 'src/environments/environment';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { AxonsCloudService } from 'src/app/shared/service/cloud-service';
import { Observable } from 'rxjs';
import { AuthStateSession } from '@axons-library/portal-js-sdk';
import { Store } from '@ngxs/store';
import { LoginSSo } from 'src/app/store/auth/auth.actions';
import { AlertService } from '../../services/alert.service';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-cloud-service',
  templateUrl: './cloud-service.component.html',
  styleUrls: ['./cloud-service.component.scss'],
})
export class CloudServiceComponent implements OnInit {
  authState$: Observable<AuthStateSession | null>;
  loading$: Observable<boolean>;
  newFeature = featureFlag.close_feature;
  language = 'en';
  themeList: ThemeList;
  isMobile;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'compact';
  queryParam: any;

  externalLink = null;
  errorDetail = '';
  errorMessages = '';

  externalLinkOld = {
    faq: 'https://cpfito365.sharepoint.com/:u:/s/SAPERPProject/EZserTifNR9Kmz9FO_kBPowBDqImP_6Pu_DfIr8yZVvfLg?e=tbBXun',
    feedback: 'https://scrm.cpf.co.th/',
    rating: 'https://forms.office.com/r/8WcpueMG8r',
  };

  externalLinkNew = {
    // eslint-disable-next-line max-len
    faq: 'https://docs.google.com/presentation/d/1ybZh_c4hYrDII9K4Hr-DLGAjnsxFfl2G/edit?usp=sharing&ouid=117556539342367831444&rtpof=true&sd=true',
    feedback:
      ' https://docs.google.com/forms/d/e/1FAIpQLSd5T9uXjUHA4gXOAKr-2F1u2EHEmKGZxeTtQRDv5ZvnNf71Rw/viewform?usp=pp_url',
    // eslint-disable-next-line max-len
    help: 'https://docs.google.com/presentation/d/1rbYFQMpnXyslhtQYbQw_f1p2KrmETEMt/edit?usp=sharing&ouid=117556539342367831444&rtpof=true&sd=true',
    policy: ' https://www.cpfworldwide.com/th/privacynotice',
  };

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private themeService: ThemeService,
    private axonsCloudService: AxonsCloudService,
    private zone: NgZone,
    private alert: AlertService,
    private spinner: SpinnerService,
  ) {
    this.language = this.translate.currentLang;
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
    this.externalLink = featureFlag.close_feature
      ? this.externalLinkNew
      : this.externalLinkOld;

    this.authState$ = this.axonsCloudService.authState$;
    this.loading$ = this.axonsCloudService.loading$;
  }

  async ngOnInit() {
    this.changeLanguage(this.language);
    this.isMobile = this.themeService.isMobile();
    this.checkResponsive();

    this.route.queryParams.subscribe(async (params) => {
      const sessionKey = params['sessionKey'];
      const stopRedirect = params['stopRedirect'];

      if (sessionKey) {
        await this.axonsCloudService.silentLogin(sessionKey);
      }

      const session = this.axonsCloudService.getAuthSession();

      if (!sessionKey && !session) {
        this.axonsCloudService.loginWithRedirect();
      }

      if (!stopRedirect && session) {
        this.handleLoginApp(session);
      }
    });
  }

  onResize(event): void {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
    this.checkResponsive();
  }

  checkResponsive(): void {
    if (this.isMobile) {
      this.size = 'compact';
    } else {
      this.size = 'normal';
    }
  }

  changeLanguage(lang: string): void {
    this.language = lang;
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    }
    if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }

  handleLoginApp(session: AuthStateSession) {
    if (session.state === 'valid') {
      this.store
        .dispatch(
          new LoginSSo({
            code: session.accessToken,
            type: 'cloud-service',
          }),
        )
        .subscribe(
          (response) => {
            // console.log('response: ', response);
            this.handleLoginSuccess(response);
          },
          (error) => {
            return this.handleLoginError(error);
          },
        );
    } else {
      console.log('Error session: ', session);
    }
  }

  loginNavigate(): void {
    this.router.navigate(['/login']);
  }

  login() {
    this.axonsCloudService.loginWithRedirect();
  }

  async logout() {
    localStorage.removeItem('is_cloud_service');
    await this.axonsCloudService.clearTokens();
    await this.axonsCloudService.logout();
  }

  private handleLoginSuccess(response: any): void {
    if (this.isAccountActivationRequired(response)) {
      this.navigateAndReload('/activate-account');
      return;
    }

    if (this.isApprovalPending()) {
      const { approval, lang } = this.queryParam;
      this.router.navigateByUrl(`/approval/${approval}?lang=${lang}`);
      return;
    }

    if (this.queryParam?.url) {
      this.router.navigateByUrl(`${this.queryParam?.url}`);
      return;
    }

    this.handleRoleBasedNavigation(response.auth.role);
  }

  private isAccountActivationRequired(response: any): boolean {
    return (
      !response.auth.is_set_password ||
      !response.auth.is_pass_t_c ||
      response.auth.is_password_expired ||
      (!response.auth.otp_type &&
        ['General', 'HR'].includes(response.auth.role))
    );
  }

  private isApprovalPending(): boolean {
    return Boolean(this.queryParam?.approval);
  }

  private handleRoleBasedNavigation(role: string): void {
    const destination =
      role === 'General' || role === 'HR' ? '/memos' : '/users';
    this.zone.run(() => this.router.navigate([destination]));
  }

  private handleLoginError(error: any): void {
    const message = error?.error?.message || '';
    if (
      message === 'User does not exist' ||
      message === 'User is disabled'
    ) {
      this.errorMessages = this.translate.instant(
        'AUTH.User access denied title',
      );
      this.errorDetail = this.translate.instant(
        'AUTH.User access denied detail',
      );
    } else {
      const detail =
        message || this.translate.instant('ERROR.CONTACT-DEV');

      this.errorMessages = this.translate.instant(
        'LOG.CAN NOT LOGIN',
      );
      this.errorDetail = detail;
    }

    this.alert.error(this.errorMessages);
    this.spinner.hide();
  }

  private navigateAndReload(route: string): void {
    this.zone.run(() => {
      this.router.navigate([route]).then(() => location.reload());
    });
  }
}
