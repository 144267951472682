<div *ngIf="loading$ | async" class="loading-indicator">
  <div>cloud service processing - please wait ...</div>
</div>

<ng-container *ngIf="errorMessages">
  <div
    class="d-flex justify-content-center align-items-center"
    style="background-color: #ffffff; width: 100vw; height: 100vh"
  >
    <app-error-card
      [title]="errorMessages"
      [message]="errorDetail"
      [buttonText]="'LOGIN.BACK-LOGIN' | translate"
      [buttonLink]="'/login'"
    ></app-error-card>
  </div>
</ng-container>
