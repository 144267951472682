import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { AlertService } from '../../../core/services/alert.service';
import { UploadMemoTypeService } from '../../service/upload-memo-type.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-upload-file-excel',
  templateUrl: './upload-file-excel.component.html',
  styleUrls: ['./upload-file-excel.component.scss'],
})
export class UploadFileExcelComponent {
  exampleFile: any;

  @Input() defaultFileName = 'import_example.xlsx';
  @Input() fileInput;
  @Input() enableCustomFileName = true;
  /** The 1st priority file name */
  @Input() customFileName: string;
  @Output() fileOutput = new EventEmitter();
  @Input() formDisable;
  @Output() formDisableChange = new EventEmitter();
  /**
   * The 2nd priority file name, this is used when `customFileName == null`.
   * The text will replaced `[typeExcel]` tag with `typeExcel` property.
   */
  @Input() templateFileName = 'import_[typeExcel]_example.xlsx';
  @Input() typeExcel: string;
  @Input() disable;
  @Input() isBroadcastUpload;

  constructor(
    private apiService: UploadMemoTypeService,
    private alert: AlertService,
  ) {}

  loadExcel(): void {
    this.apiService.batchUploadExample(this.typeExcel).subscribe(
      (res) => {
        this.exampleFile = new Blob([res.body]);
        const filename = res.headers.get('x-file-name');
        this.downloadFile(
          this.exampleFile,
          this.enableCustomFileName ? this.fileName : filename,
        );
      },
      () => {
        this.alert.error('error');
      },
    );
  }

  get disableUpload() {
    return this.disable;
  }

  downloadFile(file: File, filename?: string): void {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = filename || file.name;
    link.click();
  }

  deleteFile(fileSignature): void {
    fileSignature.value = '';
    this.fileOutput.emit('');
    this.formDisable = false;
  }

  fileChangeEvent(file): void {
    if (file.target.files[0]) {
      this.fileOutput.emit(file.target.files[0]);
      file.target.value = '';
      this.formDisable = true;
    }
  }

  get fileName(): string {
    if (this.customFileName) {
      return this.customFileName;
    } else if (this.templateFileName) {
      return this.templateFileName.replace(
        '[typeExcel]',
        this.typeExcel,
      );
    }
    return this.defaultFileName;
  }

  dropped(event): void {
    for (const droppedFile of event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry =
          droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          if (!this.checkFileSize(file)) {
            return;
          }
          this.fileOutput.emit(file);
        });
      }
    }
  }

  selectFile(fileInput) {
    _.map(fileInput.target.files, (file) => {
      if (!this.checkFileSize(file)) {
        return;
      }
      this.fileOutput.emit(file);
    });
    fileInput.target.value = '';
  }

  checkFileSize(file: File): boolean {
    if (file != null && file.size / (1024 * 1024) >= 30) {
      this.alert.error('กรุณาอัปโหลดไฟล์ขนาดไม่เกิน 30 MB');
      return false;
    }
    return true;
  }
}
