<div class="comment-container">

  <textarea *ngIf="isNonUser"
      rows="1"
      placeholder="{{'MEMOS.COMMENT-HERE'|translate}}"
      autosize
      [(ngModel)]="newComment"
      [minRows]="1"
      [maxRows]="6"
      (keydown.enter)="handleEnterKey($event)">
  </textarea>

  <div #messageInput
      id="messageInput"
      *ngIf="!isNonUser"
      class="textarea-post post-width custom-box"
      [attr.placeholder]="'MEMOS.COMMENT-HERE'|translate"
      [(ngModel)]="newComment"
      [minRows]="1"
      [maxRows]="6"
      [mention]="profileList"
      [mentionConfig]="mentionConfig"
      (searchTerm)="triggerDebouncer($event)"
      (opened)="mentionOpened()"
      (closed)="mentionClosed()"
      (keydown.enter)="handleEnterKey($event)"
      [preventEnter]="true"
      autosize
      appContentEditable
      appMentionHighlight>
  </div>

  <svg-icon class="glyphicon comment-icon"
      src="assets/images/icons/attachment.svg"
      [svgStyle]="{'transform': 'rotate(0)'}"
      (click)="image.click()"></svg-icon>
  <svg-icon class="glyphicon comment-icon"
      src="assets/images/icons/send.svg"
      [svgStyle]="{'transform': 'rotate(0)'}"
      (click)="addComment()"></svg-icon>
  <input type="file"
      accept="audio/*,video/*,image/*,.csv,.pptx,.pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
      application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      class="form-control m-t-10"
      [(ngModel)]="fileUpload"
      (change)="uploadFile($event)"
      #image
      hidden>
</div>