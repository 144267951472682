import { environment, sso } from '../../../environments/environment';

const baseUrl = environment.baseUrl + '/api';

export const apiUrl = {
  login: baseUrl + '/token-auth/',
  users: baseUrl + '/profiles/',
};

export const ADFS_URL = {
  login: environment.baseUrl + '/api/oauth2/login/',
};

export const SSO_URL = {
  cpfconnect: {
    url: sso.cpfconnect.url,
    responseType: sso.cpfconnect.response_type,
    clientId: sso.cpfconnect.client_id,
    redirectUri: sso.cpfconnect.redirect_uri,
    scope: sso.cpfconnect.scope,
    state: sso.cpfconnect.state,
    contactUs: sso.cpfconnect.contact_us || '',
  },
};
