import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AlertService } from 'src/app/core/services/alert.service';
import { MemoListDetail } from 'src/app/modules/memos/model/memo.model';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { Memo } from 'src/app/store/memo/memo.model';
import { NotificationService } from '../../service/notification.service';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { OtpModalComponent } from '../otp-modal/otp-modal.component';
export type ActionType = 'approve' | 'reject' | 'terminate';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() isSaved: boolean;
  @Input() header;
  @Output() confirmOtpSubmit = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() otpChange = new EventEmitter<string>();
  @Input() memoDetail;
  @Input() isDashBoard;
  @Input() subTitile;
  @ViewChild('confirmSuccess', { static: true })
  confirmSuccess: ElementRef;
  @ViewChild('confirmSuccessFromDashboard', { static: true })
  confirmSuccessFromDashboard: ElementRef;
  @ViewChild('confirmAlertMessage', { static: true })
  confirmAlertMessage: ElementRef;

  @Input() isApprovalList = false;
  @Input() count = 0;
  @Output() approvalSuccess = new EventEmitter();
  memoId: any;
  memoList: MemoListDetail;
  memos: Memo;
  themeList: ThemeList;
  actionType: ActionType;
  textMessage = '';
  queryParam: any;
  textTimeRemaining : string
  constructor(
    private store: Store,
    public modalService: NgbModal,
    private notification: NotificationService,
    private router: Router,
    private themeService: ThemeService,
    private alert: AlertService,
    private translate: TranslateService,
    private zone: NgZone,
    private activateRoute: ActivatedRoute,
    private memoService: MemoService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
    this.activateRoute.queryParams.subscribe((res) => {
      this.queryParam = res;
    });
  }

  /**
   * Open the confirmation modal
   * @param action This parameter specify wording and UI.
   */
  open(action: ActionType): void {
    this.actionType = action;
    if (this.actionType === 'approve') {
      this.textMessage = this.translate.instant(
        'APPROVAL.APPROVAL-DOCUMENT',
      );
    } else if (this.actionType === 'reject') {
      this.textMessage = this.translate.instant(
        'APPROVAL.REJECT-DOCUMENT',
      );
    } else {
      this.textMessage = this.translate.instant(
        this.memoDetail.memo_type === 'broadcast'
          ? 'APPROVAL.TERMINATE-DOCUMENT-BC'
          : 'APPROVAL.TERMINATE-DOCUMENT',
      );
    }

    this.modalService.open(
      this.isDashBoard
        ? this.confirmSuccessFromDashboard
        : this.confirmSuccess,
      {
        backdrop: 'static',
        centered: true,
        keyboard: false,
      },
    );
  }

  openNDA(time:string): void {
    this.textMessage = this.translate.instant(
      'APPROVAL.APPROVAL-DOCUMENT',
    );
    this.subTitile = this.translate.instant(
      'APPROVAL.WAITING-APPROVE-NDA',
    ) ;
    this.textTimeRemaining = time
    this.modalService.open(
      this.isDashBoard
        ? this.confirmSuccessFromDashboard
        : this.confirmSuccess,
      {
        backdrop: 'static',
        centered: true,
        keyboard: false,
      },
    );
  }

  openOtpModal(): void {
    this.modalService.open(this.confirmAlertMessage, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
    });
  }
  close() {
    this.modalService.dismissAll();
    this.closeModal.emit();
  }

  closeMessage() {
    this.modalService.dismissAll();
    this.approvalSuccess.emit();
  }

  previousPage() {
    this.closeModal.emit();
    this.modalService.dismissAll();
    this.notification.updateNotificationCount();
    this.memoService.onApproved();
  }

  goToMemoDetail() {
    const id = this.store.selectSnapshot(
      (s) => s.memoCreationData.id,
    );
    this.router.navigate(['memos', 'preview', id]);
    this.modalService.dismissAll();
  }
  onConfirmClick() {
    this.confirmOtpSubmit.emit();
  }

  goToApprovalDetail(): void {
    const id = this.store.selectSnapshot(
      (s) => s.memoCreationData.id,
    );
    const approvalParams = this.queryParam;
    this.zone.run(() => {
      this.router
        .navigateByUrl(
          `/approval/detail/${id}?lang=${approvalParams.lang}&user=true`,
        )
        .then(() => {
          location.reload();
        });
    });
    this.modalService.dismissAll();
  }

  get replaceWord() {
    const word = this.translate.instant(
      'APPROVAL.Do you want to continue approving the remaining Memos documents',
    );
    const newWord = word.replace('Memos', this.count - 1);
    return newWord;
  }
}
