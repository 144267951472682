<!-- OTP by google auth -->
<ng-template #otpGoogleAuthen let-modal>
  <div class="modal-header">
    <div class="flex-grow-1 font-18 font-weight-bold">
      {{"APPROVAL.ENTER-OTP" | translate}}
    </div>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body custom-box px-0">
    <div class="text-center mt-3 mb-3">
      <div class="mb-5">
          {{"PROFILE.Enter 6-digit code authenticator application"|translate}}
      </div>
      <input class="fontSize col-8" type="text"
          maxlength="6"
          [(ngModel)]="otp"
          (keypress)="numberOnly($event)"
          (keydown.enter)="otpChange.emit(this.otp)">
    </div>
    <div class="form-group text-center mt-5">
      <button [disabled]="invalidOTP" (click)="otpChange.emit(this.otp);onSaveGtag()" [ladda]="isSaved"
        class="btn" type="button" appSetTheme>{{"APPROVAL.CONFIRM-OTP" | translate}}</button>
    </div>
  </div>
</ng-template>

<!-- OTP by email -->
<ng-template #otpEmail let-modal>
  <div class="modal-header">
    <div class="flex-grow-1 font-18 font-weight-bold">
      {{"APPROVAL.ENTER-OTP" | translate}}
    </div>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center mb-5">
      <div>
        {{"CREATE-USER.EMAIL-OTP-SENT" | translate}} {{ hiddenEmail }} (Ref: {{refDisPlay}})
      </div>
      <div class="countdown" [class.text-danger]="timeLeft === 0">
        {{ timeLeft * 1000 | date:'mm:ss' }} {{"CREATE-USER.MIN-LEFT" | translate}}
      </div>
    </div>
    <div class="text-center mt-3 mb-3 custom-box">
      <input class="fontSize col-8" type="text"
          maxlength="6"
          [(ngModel)]="otp"
          (keypress)="numberOnly($event)"
          (keydown.enter)="otpChange.emit(this.otp)">
    </div>

    <div class="form-group text-center mt-5">
      <button [disabled]="invalidOTP" (click)="otpChange.emit(this.otp);onSaveGtag()" [ladda]="isSaved || isLoadingOTP"
        class="btn" type="button" appSetTheme>{{"APPROVAL.CONFIRM-OTP" | translate}}</button>
    </div>
    <div class="text-center mt-3">
      <span class="underline mr-2 pointer" (click)="resend()">{{"CREATE-USER.RESEND-OTP" | translate}}</span>
    </div>
  </div>
</ng-template>

<!-- OTP by phone number -->
<ng-template #otpPhoneNumber let-modal>
  <div class="modal-header">
    <div class="flex-grow-1 font-18 font-weight-bold">
      {{"APPROVAL.ENTER-OTP" | translate}}
    </div>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center mb-5">
      <div>
        {{"CREATE-USER.OTP-SENT" | translate}} {{ hiddenNumber }} (Ref: {{refDisPlay}})
      </div>
      <div class="countdown" [class.text-danger]="timeLeft === 0">
        {{ timeLeft * 1000 | date:'mm:ss' }} {{"CREATE-USER.MIN-LEFT" | translate}}
      </div>
    </div>
    <div class="text-center mt-3 mb-3 custom-box">
      <input class="fontSize col-8" type="text"
          maxlength="6"
          [(ngModel)]="otp"
          (keypress)="numberOnly($event)"
          (keydown.enter)="otpChange.emit(this.otp)">
    </div>

    <div class="form-group text-center mt-5">
      <button [disabled]="invalidOTP" (click)="otpChange.emit(this.otp);onSaveGtag()" [ladda]="isSaved || isLoadingOTP"
        class="btn" type="button" appSetTheme>{{"APPROVAL.CONFIRM-OTP" | translate}}</button>
    </div>
    <div class="text-center mt-3">
      <span class="underline mr-2 pointer" (click)="resend()">{{"CREATE-USER.RESEND-OTP" | translate}}</span>
    </div>
  </div>
</ng-template>
