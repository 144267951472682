<div class="container">
  <div class="content-frame">
    <div class="bg">
      <div class="content row">
        <div class="col-lg-12 col-md-12">
          <img src="assets/images/website_maintenance.svg" class="mb-5 justify-content-end size-image">
          <h5 class="mt-2"> 
            ขออภัย ขณะนี้กำลังปรับปรุงระบบอยู่ <br> 
            <p [innerText]="messageTh"></p>
          </h5>
          <h5 class="mt-2"> 
            Sorry, Website is in maintenance mode <br>
            <p class="mb-5"  [innerText]="messageEn"></p>
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>