import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-card',
  templateUrl: './error-card.component.html',
  styleUrls: ['./error-card.component.scss'],
})
export class ErrorCardComponent implements OnInit {
  @Input() title: string = 'คุณไม่มีสิทธิ์ใช้งานแอปพลิเคชันนี้';
  @Input() message: string =
    'กรุณาติดต่อผู้ดูแลระบบดังกล่าว เพื่อขอสิทธิ์เข้าถึงการใช้งาน';
  @Input() buttonText: string = 'กลับไปก่อนหน้า';
  @Input() buttonLink: string;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  buttonClick() {
    if (this.buttonLink) {
      // window.open(this.buttonLink, '_self').focus();
      this.router.navigateByUrl(this.buttonLink);
    } else {
      window.history.back();
    }
  }
}
