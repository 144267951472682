import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  refreshToken(token: string) {
    return this.http.post(this.baseUrl + '/api/token-refresh/', {
      refresh: token,
    });
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refresh');
  }

  saveToken(token: string): void {
    localStorage.removeItem('currentUser');
    localStorage.setItem('currentUser', token);
  }
}
