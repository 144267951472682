import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(private http: ApiService) {}

  getBannerList(data?): Observable<any> {
    return this.http.get(`/api/banner/`, data);
  }

  addBanner(data): Observable<any> {
    return this.http.post(`/api/banner/`, data);
  }

  getBannerToday(): Observable<any> {
    return this.http.get(`/api/banner_today/`);
  }

  editBanner(data, id): Observable<any> {
    return this.http.patch(`/api/banner/${id}/`, data);
  }

  deleteBanner(id): Observable<any> {
    return this.http.delete(`/api/banner/${id}`);
  }

  reorderBanner(data): Observable<any> {
    return this.http.patch(`/api/banner/reorder/`, data);
  }

  getActiveBanner(): Observable<any> {
    return this.http.get(`/api/banner/global-status/`);
  }
  activeBanner(data): Observable<any> {
    return this.http.patch(`/api/banner/set-global-status/`, data);
  }
}
