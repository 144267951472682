import { Component, OnInit } from '@angular/core';
import { AxonsCloudService } from '../../service/cloud-service';
import { ThemeList, ThemeService } from '../../service/theme.service';

@Component({
  selector: 'app-widget-ninedot',
  templateUrl: './widget-ninedot.component.html',
  styleUrls: ['./widget-ninedot.component.scss'],
})
export class WidgetNinedotComponent implements OnInit {
  themeList: ThemeList;
  hoverList = {
    lang: false,
    notification: false,
  };

  constructor(
    private axonsCloudService: AxonsCloudService,
    private themeService: ThemeService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnInit(): void {
    this.axonsCloudService.initNineDotWidget();
  }

  toggleNineDot() {
    this.axonsCloudService.toggleNineDot();
  }
}
