import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import { MarkerIdentity } from '../../modules/memos/components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';
import { Injectable } from '@angular/core';
import {
  ClearDrawing,
  ClearStateDrawing,
  CloseDrawing,
  OpenDrawing,
  SaveDrawing,
  UpdateDrawingOption,
} from './drawing.actions';
import { ImageDatas } from './drawing.model';

export class DrawingStateModel {
  public selected: MarkerIdentity;
  public imageDatas: ImageDatas;
}
const DRAWING_STATE_TOKEN = new StateToken<DrawingStateModel>(
  'drawing',
);

@State<DrawingStateModel>({
  name: DRAWING_STATE_TOKEN,
  defaults: {
    selected: null,
    imageDatas: {},
  },
})
@Injectable({
  providedIn: 'root',
})
export class DrawingState {
  constructor() {}

  @Selector()
  static selected(state: DrawingStateModel) {
    return state.selected;
  }

  @Selector()
  static imageDatas(state: DrawingStateModel) {
    return state.imageDatas;
  }

  @Selector()
  static color(state: DrawingStateModel) {
    return state.selected?.color ?? '#000000';
  }

  @Selector()
  static thickness(state: DrawingStateModel) {
    return state.selected?.thickness ?? 5;
  }

  @Action(OpenDrawing)
  openDrawing(
    ctx: StateContext<DrawingStateModel>,
    action: OpenDrawing,
  ) {
    ctx.patchState({
      selected: action.selected,
    });
  }

  @Action(SaveDrawing)
  saveDrawing(
    ctx: StateContext<DrawingStateModel>,
    action: SaveDrawing,
  ) {
    const state = ctx.getState();
    const id = action.marker.fe_drawing_id;
    ctx.patchState({
      imageDatas: {
        ...state.imageDatas,
        [id]: {
          url: action.url,
          blob: action.blob,
          updates: [...action.updates],
        },
      },
    });

    return ctx.dispatch(new CloseDrawing());
  }

  @Action(ClearDrawing)
  clearDrawing(
    ctx: StateContext<DrawingStateModel>,
    action: ClearDrawing,
  ) {
    const { imageDatas } = ctx.getState();
    const id = action.marker.fe_drawing_id;
    if (id in imageDatas) {
      delete imageDatas[id];
    }
    ctx.patchState({
      imageDatas: {
        ...imageDatas,
      },
    });

    return ctx.dispatch(new CloseDrawing());
  }

  @Action(CloseDrawing)
  closeDrawing(ctx: StateContext<DrawingStateModel>) {
    ctx.patchState({
      selected: null,
    });
  }

  @Action(UpdateDrawingOption)
  updateDrawingOption(
    ctx: StateContext<DrawingStateModel>,
    action: UpdateDrawingOption,
  ) {
    const { selected } = ctx.getState();
    ctx.patchState({
      selected: {
        ...selected,
        [action.field]: action.value,
      },
    });
  }

  @Action(ClearStateDrawing)
  clearStateDrawing({
    setState,
  }: StateContext<DrawingStateModel>): void {
    setState({
      // Reset the state to its initial value
      // You can also directly use the defaults from the @State decorator
      selected: null,
      imageDatas: {},
    });
  }
}
