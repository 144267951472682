<div class="pdf-view">
  <div class="page-control w-100 position-relative justify-content-center align-items-center">
    <svg-icon src="assets/images/icons/back2.svg" class="pointer"
      [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
      style="width: 20px;height: 25px;margin-right: 10px;" (click)="changeDocumentPageBack()">
    </svg-icon>
    <div ngbDropdown class="d-inline-block" autoClose="true">
      <span>{{"UPLOAD.PAGE"|translate}}</span>
      <button class="btn page-btn mx-1" ngbDropdownToggle>{{currentDocumentPage}}</button>
      <span>{{"UPLOAD.OF"|translate}}&nbsp;{{totalDocumentPage}}</span>
      <div ngbDropdownMenu>
        <ng-container *ngFor="let page of documentPages">
          <div style="margin: 5px;">
            <button ngbDropdownItem [ngClass]="{'active': page === currentDocumentPage}"
              (click)="changeDocumentPage(page)">{{page}}</button>
          </div>
        </ng-container>
      </div>
    </div>
    <svg-icon src="assets/images/icons/next2.svg" class="pointer"
      [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
      style="width: 20px;height: 25px;margin-left: 10px;" (click)="changeDocumentPageNext()">
    </svg-icon>
    <div *ngIf="showDownload" class="d-none d-md-block icon position-absolute pointer p-1" style="right: 10px"
      (click)="downloaded.emit()">
      <svg-icon src="assets/images/icon_download2.svg"
        [svgStyle]="{'width.px': 13, 'height.px': 13}"
        [style.--c]="themeList?.header_table_color"></svg-icon>
      <span [style.color]="themeList?.header_table_color" class="underline ml-2">{{ 'MEMOS.DOWNLOAD'|translate }}</span>
    </div>
  </div>
  <div class="page-view pdf-shadow" (click)="clickPreview.emit()">
    <app-loading-spinner *ngIf="isPdfLoading"></app-loading-spinner>
    <pdf-viewer class="h-100"
      [src]="{url: pdfFile, httpHeaders: httpHeaders}"
      [ngClass]="{'border': !isDocumentLoadingPage}"
      [(page)]="currentDocumentPage" [render-text]="true" [render-text-mode]="1"
      [original-size]="false" [autoresize]="true" [stick-to-page]="false" [fit-to-page]="true"
      [show-all]="false" [show-borders]="false" (after-load-complete)="onLoadPdfComplete($event)"
      style="display: block; max-width: 225mm; margin: auto;height: inherit">
    </pdf-viewer>
  </div>
</div>
