import { Injectable } from '@angular/core';
import { Memo } from '../../../../../store/memo/memo.model';
import { MarkerIdentity } from '../pdf-signature-customizer/pdf-signature-customizer.model';
import { Store } from '@ngxs/store';
import { ImageDatas } from '../../../../../store/drawing/drawing.model';
import { AlertService } from '../../../../../core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class DrawingService {
  constructor(
    private store: Store,
    private alert: AlertService,
    private translate: TranslateService,
    private http: ApiService,
  ) {}

  updateFormData(fd: FormData, memo: Memo): FormData {
    const positions = this.getDrawingPositions(memo);

    const imageDatas: ImageDatas = this.store.selectSnapshot(
      (s) => s.drawing?.imageDatas,
    );

    const uniqueId = new Set();
    positions.forEach((p) => {
      if (!p.fe_drawing_id) {
        return;
      }
      if (uniqueId.has(p.fe_drawing_id)) {
        return;
      }
      if (p.fe_drawing_id in imageDatas) {
        const blob = imageDatas[p.fe_drawing_id]?.blob;
        if (blob) {
          fd.set(`drawing_img_${p.fe_drawing_id}`, blob);
          uniqueId.add(p.fe_drawing_id);
        }
      }
    });
    return fd;
  }

  getDrawingPositions(memo: Memo): MarkerIdentity[] {
    if (memo?.memo_type !== 'upload') {
      return [];
    }

    const currentLevel = memo?.current_level;
    if (!currentLevel) {
      return [];
    }

    const currentMarker = memo.upload?.signature_position?.find(
      (marker) => marker.level === currentLevel,
    );
    if (!currentMarker) {
      return [];
    }
    if (!currentMarker?.drawing_positions) {
      return [];
    }
    return currentMarker?.drawing_positions;
  }

  passedRequired(memo: Memo, alert = true): boolean {
    const positions = this.getDrawingPositions(memo);
    if (positions.length <= 0) {
      return true;
    }

    const imageDatas: ImageDatas = this.store.selectSnapshot(
      (s) => s.drawing?.imageDatas,
    );

    const ok = positions.every((p) => {
      if (!p.required) {
        return true;
      }
      return p.fe_drawing_id in imageDatas;
    });

    if (alert && !ok) {
      // this.alert.error(
      //   this.translate.instant('UPLOAD.Please create a drawing'),
      // );
    }

    return ok;
  }

  checkIsDrawing(
    curMarker: MarkerIdentity,
    activeMarker: MarkerIdentity,
  ): boolean {
    if (!curMarker?.id) {
      return false;
    } else {
      return activeMarker?.id === curMarker?.id;
    }
  }
  
  getSpaceRemaining(memo) {
    return this.http.get(
      `/api/memo-attachments/space_remaining/`,
      memo,
    );
  }
}
