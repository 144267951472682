<ng-template #signSignature
    let-c="close"
    let-d="dismiss">
  <div class="modal-header"
      (window:resize)="onResize($event)">
    <div class="col">
      <h4 class="mb-1">
        {{
        isSignNow
        ? ('APPROVAL.SIGN-SIGNATURE' | translate)
        : !isMobile?('APPROVAL.CONFIRM-SIGNATURE' | translate):
        ('SELECT-SIGN-METHOD.TITLE-CONFIRM' | translate)
        }}
      </h4>
    </div>
    <div class="col-auto">
      <i class="fas fa-times pointer size-24px"
          data-dismiss="modal"
          (click)="close()"></i>
    </div>
  </div>
  <div class="modal-body"
      [ngClass]="isMobile? 'pt-0': ''">
    <ng-container *ngTemplateOutlet="isSignNow ? signEditor : signSignatureDetail">
    </ng-container>

    <div [ngClass]="{ 'd-none': !signImageBlob }">
      <div class="justify-content-center"
          style="position: relative">
        <div class="message-box">
          <span class="message-error">{{
            errorMsg | translate
            }}</span>
        </div>
        <div class="justify-content-end mt-5 btn-container text-right"
            [style.--c]="themeList?.header_table_color"
            [style.--bg]="'#fff'">
          <button type="button"
              [ngClass]="isMobile? 'd-none': ''"
              class="btn btn-outline mr-2 w-auto"
              (click)="
              isUpload ? reOpenSelectSignModal() : clearSignature()
            ">
            {{ 'APPROVAL.CANCEL' | translate }}
          </button>
          <button type="button"
              class="btn"
              appSetTheme
              [ngClass]="isMobile? 'btn-crop-mobile': ''"
              (click)="saveSignature()">
            {{ 'CREATE-USER.CONTINUE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #signEditor>
  <div class="row"
      (window:resize)="onResize($event)">
    <div class="col m-t-20 m-b-20"
        [ngClass]="checkMobileLandscape? 'px-5': ''">
      <div class="signature-sign-container"
          [style.--bg]="themeList?.header_table_color">
        <div class="signature-sign-options"
            (clickOutside)="closeTool()"
            [ngClass]="checkMobileLandscape? 'custom-tool-sign': ''">
          <button class="signature-sign-btn"
              ngbTooltip="{{ 'MEMOS.EDIT' | translate }}"
              (click)="onEditSizeLine()">
            <svg-icon src="assets/svg/pen.svg"
                [svgStyle]="{'fill': '#fff', 'height.px': 15, 'width.px': 15}">
            </svg-icon>
          </button>
          <button class="signature-sign-btn"
              ngbTooltip="{{ 'MEMOS.DELETE' | translate }}"
              (click)="onClearCanvas()">
            <svg-icon src="assets/svg/delete-2.svg"
                [svgStyle]="{'fill': '#fff', 'height.px': 15, 'width.px': 15}">
            </svg-icon>
          </button>
          <button class="signature-sign-btn"
              ngbTooltip="{{ 'MEMOS.UNDO' | translate }}"
              (click)="onUndoCanvas()">
            <svg-icon src="assets/svg/undo.svg"
                [svgStyle]="{'fill': '#fff', 'height.px': 15, 'width.px': 15}">
            </svg-icon>
          </button>
          <button class="signature-sign-btn"
              ngbTooltip="{{ 'MEMOS.COLOR' | translate }}"
              (click)="onClickColorPicker()">
            <svg-icon src="assets/svg/color-palette.svg"
                [svgStyle]="{'fill': '#fff', 'height.px': 15, 'width.px': 15}">
            </svg-icon>
          </button>
          <div *ngIf="showPopupLine"
              class="resize-popup-window">
            <div class="d-flex">
              <input type="range"
                  min="1"
                  max="30"
                  [(ngModel)]="signatureThickness"
                  (change)="resizeLine($event)"
                  class="slider" />
              <span class="ml-2">{{ signatureThickness }}</span>
            </div>
            <div class="text-right">
              <span class="text-red pointer"
                  (click)="resetSizeLine()">reset</span>
            </div>
          </div>
          <div *ngIf="showColorPicker"
              class="color-popup-window">
            <!-- default color list-->
            <div class="color-list">
              <span class="color-item-border"
                  *ngFor="let color of signatureColorList"
                  [style.border-color]="
                  color === selectedColor ? color : 'white'
                "
                  (click)="onSelectedColor(color)">
                <span class="color-item"
                    [style.background]="color"></span>
              </span>
            </div>
            <!-- custom color-->
            <div class="color-custom">
              <color-sketch (onChangeComplete)="changeComplete($event)"></color-sketch>
            </div>
          </div>
        </div>
        <div #canvasContainer
            class="signature-sign-wrap"
            [ngClass]="checkMobileLandscape? 'custom-mt-3': ''"
            [ngStyle]="{'height': '300px'}">
          <canvas-whiteboard #canvasWhiteboard
              [clearButtonEnabled]="false"
              [drawButtonEnabled]="false"
              [drawingEnabled]="true"
              [undoButtonEnabled]="false"
              [redoButtonEnabled]="false"
              [colorPickerEnabled]="false"
              [saveDataButtonEnabled]="false"
              [shapeSelectorEnabled]="false"
              [lineWidth]="
              getSignatureThickness(canvasContainer.offsetWidth)
            "
              [startingColor]="'transparent'"
              [shouldDownloadDrawing]="false"
              [strokeColor]="selectedColor"
              (onSave)="onSave($event)">
          </canvas-whiteboard>
        </div>
      </div>
    </div>
  </div>
  <div class="text-right mt-4 btn-container"
      [style.--c]="themeList?.header_table_color"
      [style.--bg]="'#fff'">
    <button type="button"
        [ngClass]="isMobile? 'd-none': ''"
        class="btn btn-outline mr-2 w-auto display-none"
        (click)="reOpenSelectSignModal()">
      {{ 'APPROVAL.CANCEL' | translate }}
    </button>
    <button type="button"
        class="btn"
        [ngClass]="[isMobile ? 'btn-mobile' : '',  checkMobileLandscape ? 'btn-mobile-landScape' : '']"
        appSetTheme
        (click)="onSaveCanvas()">
      {{ 'CREATE-USER.CONTINUE' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #signSignatureDetail>
  <div *ngIf="signImageBlob">
    <h6 class="mt-2"
        *ngIf="!isMobile">
      {{ 'APPROVAL.SELECT-SIGNATURE-SCOPE' | translate }}
    </h6>
    <div class="row mt-3 mt-md-0">
      <div class="col align-self-center text-center">
        <img class="img-fluid img-thumbnail"
            *ngIf="!signImageBlob"
            [src]="'https://via.placeholder.com/1440x900?text=Original'" />
        <image-cropper *ngIf="signImageBlob"
            [imageFile]="signImageBlob"
            [imageChangedEvent]="signImageBlob"
            [maintainAspectRatio]="false"
            [cropper]="cropper"
            (cropperReady)="onCropperReady($event)"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (loadImageFailed)="loadImageFailed()"
            format="png"
            outputType="both"
            class="img-thumbnail"
            [style.--bg]="themeList?.header_table_color"
            #imageCropper>
        </image-cropper>
        <input type="file"
            class="d-none"
            (change)="fileChangeEvent($event)"
            #fileSignature />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectSignMethodModal
    let-c="close"
    let-d="dismiss"
    class="modal-dialog"
    let-modal>
  <div class="modal-header">
    <div class="
        flex-grow-1
        text-center text-uppercase
        font-18 font-weight-bold
      ">
      {{ 'SELECT-SIGN-METHOD.QUESTION' | translate }}
    </div>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="numberOfSignature"
        class="text-center numbers-of-signature">
      {{('SELECT-SIGN-METHOD.You are about to sign $numberOfSignature of $countPage pages.'|translate)
      .replaceAll('$numberOfSignature', numberOfSignature)
      .replaceAll('$countPage', countPage)}}
    </p>
    <div class="row btn-container"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'">
      <div *ngIf="enableTextSignature"
          class="col-12 col-md my-2"
          [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
        <button class="
            btn btn-outline
            d-flex
            align-items-center
            justify-content-center
            mb-3
          "
            type="button"
            [disabled]="!enableTextSignature"
            (click)="onTextSignatureClick()">
          <span class="far fa-font-case d-inline-block fa-2x px-3"></span>
          <span class="d-inline-block">
            {{ 'SELECT-SIGN-METHOD.SIGN-BY-TYPING' | translate }}
          </span>
        </button>
      </div>
      <div class="col-12 col-md my-2"
          *ngIf="!isNoneUser"
          [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
        <button class="
            btn btn-outline
            d-flex
            align-items-center
            justify-content-center
            mb-3
          "
            type="button"
            [disabled]="!enableUsingStoredSignature"
            (click)="onHandleSubmitSignStorage()">
          <svg-icon src="assets/images/icons/sign-saved.svg"
              class="mr-2"></svg-icon>
          <span class="d-inline-block">{{
            'SELECT-SIGN-METHOD.STORED' | translate
            }}</span>
        </button>
      </div>
      <div class="col-12 col-md my-2"
          [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
        <button class="
            btn btn-outline
            d-flex
            align-items-center
            justify-content-center
            mb-3
          "
            type="button"
            (click)="onSignNowClick()"
            (focus) = "OnFocusField()">
          <svg-icon src="assets/images/icons/sign-new.svg"
              class="mr-2"></svg-icon>
          <span class="d-inline-block">{{
            'SELECT-SIGN-METHOD.SIGN-NOW' | translate
            }}</span>
        </button>
      </div>
      <div class="col-12 col-md my-2"
          [ngClass]="[enableTextSignature ? 'col-md-6' : 'col-md']">
        <button class="
            btn btn-outline
            align-items-center
            justify-content-center
            mb-3
          "
            type="button"
            (click)="onDocApprovedSign('Upload');filePhoto.click();"
            [disabled]="!enableUploadSignature">
          <svg-icon src="assets/images/icons/sign-upload.svg"
              class="mr-2"></svg-icon>
          <span class="d-inline-block">{{
            'SELECT-SIGN-METHOD.UPLOAD' | translate
            }}</span>
          <div class="text-red">
            ({{
            'SELECT-SIGN-METHOD.Recommended image size' | translate
            }}: 340*80px)
          </div>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadUserSignature
    let-c="close"
    let-d="dismiss"
    class="modal-dialog">
  <div class="modal-header align-items-center">
    <div class="col">
      <h4 class="mb-1">
        {{ 'CREATE-USER.EDIT-SIGNATURE' | translate }}
      </h4>
    </div>
    <div class="col-auto">
      <i class="fas fa-times pointer size-24px"
          data-dismiss="modal"
          (click)="d('Cross click')"></i>
    </div>
  </div>
  <div class="modal-body text-center"
      *ngIf="signaturePreview">
    <img [src]="signaturePreview | secure | async"
        class="img-fluid img-thumbnail blend-hard-light" />
    <h6 class="mt-2 font-weight-bold">
      {{ 'CREATE-USER.CURRENT-SIGNATURE' | translate }}
    </h6>
  </div>
  <div class="modal-footer"
      style="position: relative">
    <button type="button"
        class="btn btn-outline-secondary gray"
        (click)="filePhoto.click()">
      {{ 'CREATE-USER.UPLOAD' | translate }}
    </button>
    <button type="button"
        class="btn btn-outline-secondary"
        *ngIf="canSignNow"
        (click)="onSignNowClick()">
      {{ 'SELECT-SIGN-METHOD.SIGN-NOW' | translate }}
    </button>
  </div>
</ng-template>

<app-otp-modal [header]="header"
    [(otp)]="otp"
    [isSaved]="isSaved"
    (otpChange)="verifyOTP()"
    (closeModal)="close()"></app-otp-modal>

<input type="file"
    accept="image/*"
    class="d-none"
    (change)="filePhotoChangeEvent($event)"
    #filePhoto />

<!-- <ngx-spinner name="rotate"
    [fullScreen]="true"
    template="<img src='assets/git/rotate-phone.gif' />"
    size="medium"></ngx-spinner> -->

<ngx-spinner name="rotate"
    template="<img src='assets/gif/rotate-phone-white2.gif' />">
</ngx-spinner>