<ng-template #confirmationModal
    let-c="close"
    let-d="dismiss"
    class="modal-dialog"
    let-modal>
  <div class="modal-header">
    <div class="flex-grow-1 text-center text-uppercase font-18 font-weight-bold">
      {{ 'APPROVAL.YOU-CONFIRM' | translate }} {{ 'APPROVAL.' + actionType.toUpperCase() |translate }} {{
      'APPROVAL.IS-THIS' | translate }}
    </div>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body"
      [ngClass]="enableReason?'':'custom-padding'">
    <div *ngIf="!enableReason"
        class="text-center">
      <div class="f-16 pb">{{"APPROVAL.CONTENT-NO-REQUIRED"|translate}}</div>
    </div>
    <div *ngIf="enableReason">
      <span>{{ reasonLabel | translate }}</span>
      <div class="form-group mt-2">
        <textarea class="form-control"
            fz-elastic
            [(ngModel)]="reason"
            (ngModelChange)="onReasonChange()">
        </textarea>
      </div>
    </div>
    <div class="border-0"
        [ngClass]="enableReason?'justify-content-right':'justify-content-center'">
      <div class="text-center"
          [style.--c]="themeList?.header_table_color"
          [style.--bg]="'#fff'">
        <button class="btn mr-2 btn-outline p-btn mx-2"
            (click)="close()">
          {{"APPROVAL.CANCEL" | translate}}
        </button>
        <button class="btn p-btn mx-2 confirm-btn"
            appSetTheme
            [style.--text]="themeList?.text_header_table_color"
            type="button"
            [ladda]="isSaved"
            [disabled]="enableReason && !reason"
            (click)="submit()">
          {{"APPROVAL.CONFIRM" | translate}}
        </button>
      </div>
    </div>

  </div>


</ng-template>

<ng-template #confirmationModalBroadcast>
  <div class="modal-header">
    <h5>{{'APPROVAL.DECLINATION' | translate}}</h5>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center pb">
        {{
        'APPROVAL.ARE-YOU-SURE-YOU-WANT-TO-DECLINE-THIS-DOCUMENT'
        | translate
        }}
      </div>
    </div>
    <div class="row justify-content-center px-2">
      <button class="btn p-btn btn-outline-yale-blue mx-2"
          (click)="close()">
        {{ 'APPROVAL.CANCEL' | translate }}
      </button>

      <button class="btn p-btn mx-2"
          type="button"
          appSetTheme
          (click)="submit()">
        {{ 'APPROVAL.CONFIRM' | translate }}
      </button>
    </div>
  </div>
</ng-template>