<div [ngClass]="showBanner || isPreview ? '':'d-none' ">
  <div class="modal-body">
    <ng-container *ngIf="!isPreview; else previewCarousel">
      <ng-container *ngTemplateOutlet="carouselTemplate; context: { $implicit: bannerList }"></ng-container>
    </ng-container>
    <ng-template #previewCarousel>
      <ng-container *ngTemplateOutlet="carouselTemplate; context: { $implicit: bannerListPreview }"></ng-container>
    </ng-template>

    <ng-template #carouselTemplate
        let-imagesList>
      <ngb-carousel id="carousel"
          #carouse
          [pauseOnHover]="true"
          [pauseOnFocus]="false"
          [showNavigationIndicators]="imagesList.length > 1"
          [showNavigationArrows]="imagesList.length > 1"
          (slide)="onSlide($event)">
        <ng-template *ngFor="let imgItem of imagesList; let i = index"
            [id]="i"
            ngbSlide>
          <div [ngClass]="{'pointer': imgItem?.link_url}"
              class="picsum-img-wrapper desktop mobile">
            <img [src]="onDisplayImage(imgItem) | secure | async"
                (click)="hasUrlLink ? openDetail() : false">
          </div>
          <div class="carousel-caption"></div>
        </ng-template>
      </ngb-carousel>
    </ng-template>

    <button type="button"
        class="close icon-close"
        (click)="close()"
        data-dismiss="modal"><i class="fal fa-times"></i></button>
  </div>
  <div class="modal-footer">
    <div class="box-checkbox option-form">
      <div class="form-check">
        <input class="form-check-input"
            [style.--c]="themeList?.header_table_color"
            type="checkbox"
            [disabled]="isPreview"
            [(ngModel)]="checkCloseBanner">
        <label class="form-check-label">
          {{"BANNER.Do not show again"|translate}}
        </label>
      </div>
    </div>
    <button *ngIf="hasUrlLink"
        class="btn btn-outline p-btn"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'"
        (click)="openDetail()">{{"BANNER.More detail" | translate}}</button>
  </div>
</div>
