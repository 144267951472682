<div class="signature-sign-container">
  <div class="signature-sign-wrap"
      style="height: 300px;">
    <canvas-whiteboard #canvasWhiteboard
        [clearButtonEnabled]="false"
        [options]="canvasOptions"
        [drawButtonEnabled]="false"
        [drawingEnabled]="true"
        [undoButtonEnabled]="false"
        [redoButtonEnabled]="false"
        [colorPickerEnabled]="false"
        [saveDataButtonEnabled]="false"
        [shapeSelectorEnabled]="false"
        [lineWidth]="2"
        [shouldDownloadDrawing]="false"
        [startingColor]="'white'"
        [shouldDownloadDrawing]="false"
        [strokeColor]="selectedColor"
        (onSave)="onSave($event)">
    </canvas-whiteboard>
  </div>
</div>