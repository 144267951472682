import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CertificateStatus } from '../../../../../certificate/shared/certificate.model';
import { ProfileService } from '../../../../../profile/shared/profile.service';

@Component({
  selector: 'app-signing-law-section-radio',
  templateUrl: './signing-law-section-radio.component.html',
  styleUrls: ['./signing-law-section-radio.component.scss'],
})
export class SigningLawSectionRadioComponent implements OnChanges {
  @Input() signerId: number;
  @Input() certStatusSec26: CertificateStatus;
  @Input() certStatusSec28: CertificateStatus;
  @Input() ddocLawSection: '26' | '28';
  @Output() ddocLawSectionChange = new EventEmitter();

  uniqueId: number;

  constructor(private profileService: ProfileService) {
    this.uniqueId = Math.floor(100000 + Math.random() * 900000);
  }

  ngOnChanges(changes: { [input: string]: SimpleChange }): void {
    if (changes.signerId) {
      const signerId = changes.signerId.currentValue;
      if (signerId == null) {
        this.certStatusSec26 = null;
        this.certStatusSec28 = null;
        return;
      }
      if (typeof signerId !== 'number') {
        console.error('The `signerId` input is not number');
        return;
      }
      this.fetchCertificateStatus();
    }
  }

  fetchCertificateStatus(): void {
    const certStatusSec26$ = this.profileService
      .loadCertificateStatus(this.signerId, false)
      .pipe(
        tap((data) => {
          this.certStatusSec26 = data.certificate_status;
        }),
      );
    const certStatusSec28$ = this.profileService
      .loadCertificateStatus(this.signerId, false, { type: 'm28' })
      .pipe(
        tap((data) => {
          this.certStatusSec28 = data.certificate_status;
        }),
      );
    forkJoin([certStatusSec26$, certStatusSec28$]).subscribe({
      next: (certs) => {
        const allValid = certs.every(
          (cert) => cert?.certificate_status === 'valid',
        );
        /** Set default selection when certificate is valid */
        if (allValid && this.ddocLawSection == null) {
          this.ddocLawSectionChange.emit('26');
          return;
        }
        /** Apply null if selected cert is invalid */
        const selectedCertIsValid =
          (this.ddocLawSection === '26' &&
            certs[0].certificate_status === 'valid') ||
          (this.ddocLawSection === '28' &&
            certs[1].certificate_status === 'valid');
        if (!selectedCertIsValid) {
          this.ddocLawSectionChange.emit('26');
        }
      },
      error: (error) => {
        console.log(error);
        /** Apply null if getting cert has error */
        this.ddocLawSectionChange.emit(null);
      },
    });
  }

  onDdocLawSectionChange(value: boolean): void {
    this.ddocLawSectionChange.emit(value);
  }
}
