import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from 'src/app/shared/service/api.constant';
import { Member, NonUser } from './loa.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaService {
  loaNameChangeSubject = new Subject<any>();
  checkLoaNameChange = this.loaNameChangeSubject.asObservable();

  signWithOwnerSubject = new Subject<any>();
  checkSignWithOwner = this.signWithOwnerSubject.asObservable();

  constructor(private http: ApiService) {}

  getLOAList(params?) {
    return this.http.get(API_URL.loa_groups, params);
  }

  createNewLOA(data) {
    return this.http.post(API_URL.loa_groups, data);
  }

  updateLOA(id: number, data) {
    return this.http.put(API_URL.loa_groups + id + '/', data);
  }

  removeLOA(id: number) {
    return this.http.delete(API_URL.loa_groups + id + '/');
  }

  getLOAData(id) {
    return this.http.get(API_URL.loa_groups + id + '/');
  }

  batchUploadFile(data, type) {
    return this.http.post(`/api/batch-uploads/${type}/`, data);
  }

  validateApprover(signer: Partial<Member>): boolean {
    let isValid = false;
    if (signer.person == null) {
      isValid = false;
    } else if (typeof signer.person === 'object') {
      isValid = [
        'email',
        'name',
        'job_position',
        'phone_number',
      ].every((validateKey) => {
        return !!(signer.person as NonUser)?.[validateKey];
      });
    } else if (typeof signer.person === 'number') {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  }

  onLoaNameChange(name): void {
    this.loaNameChangeSubject.next(name);
  }

  signWithOwnerChange(signer): void {
    this.signWithOwnerSubject.next(signer);
  }
}
