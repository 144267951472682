import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  NgZone,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NavbarService } from '../navbar/navbar.service';
import { SpinnerService } from '../../services/spinner.service';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { ADFS_URL } from '../../http/api.constant';
import { Select, Store } from '@ngxs/store';
import { ClearState, Login } from 'src/app/store/auth/auth.actions';
import { versionInfo } from '../../../../version-info';
import { ReCaptcha2Component } from 'ngx-captcha/public_api';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { J } from '@angular/cdk/keycodes';
import { featureFlag } from 'src/environments/environment';
import { ModalLoginComponent } from '../modal-login/modal-login.component';
import { ModalCloudServiceComponent } from '../modal-cloud-service/modal-cloud-service.component';

declare let hljs: any;
declare let gtag: Function;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  newFeature = featureFlag.close_feature;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  forgotPasswordMode = false;
  forgotPasswordForm: FormGroup;
  forgotPasswordSuccess = false;
  email = '';
  currentTime: Date;
  currentDate = moment().format('DD MMMM YYYY');
  language = 'en';
  loginMode = true;
  adfsLoginMode = true;
  textList: string;
  themeList: ThemeList;
  version = versionInfo.hash;
  checkValidate = false;
  isMobile;
  url = window.location.hostname;
  urlImage = '/assets/images/bg-left.png';
  modal: NgbModalRef;

  externalLink = null;

  externalLinkOld = {
    faq: 'https://cpfito365.sharepoint.com/:u:/s/SAPERPProject/EZserTifNR9Kmz9FO_kBPowBDqImP_6Pu_DfIr8yZVvfLg?e=tbBXun',
    feedback: 'https://scrm.cpf.co.th/',
    rating: 'https://forms.office.com/r/8WcpueMG8r',
  };

  externalLinkNew = {
    // eslint-disable-next-line max-len
    faq: 'https://docs.google.com/presentation/d/1ybZh_c4hYrDII9K4Hr-DLGAjnsxFfl2G/edit?usp=sharing&ouid=117556539342367831444&rtpof=true&sd=true',
    feedback:
      ' https://docs.google.com/forms/d/e/1FAIpQLSd5T9uXjUHA4gXOAKr-2F1u2EHEmKGZxeTtQRDv5ZvnNf71Rw/viewform?usp=pp_url',
    // eslint-disable-next-line max-len
    help: 'https://docs.google.com/presentation/d/1rbYFQMpnXyslhtQYbQw_f1p2KrmETEMt/edit?usp=sharing&ouid=117556539342367831444&rtpof=true&sd=true',
    policy: ' https://www.cpfworldwide.com/th/privacynotice',
  };

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public siteKey = '6LeoZ70dAAAAAP2ZkEanBnuBvtPsL0I97HVKHKGn';

  public aFormGroup: FormGroup;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'compact';
  public lang = 'en';
  public type: 'image' | 'audio';
  public useGlobalDomain = false;
  @ViewChild('captchaElem', { static: false })
  captchaElem: ReCaptcha2Component;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;
  @ViewChild('wrongPasswordAttempt', { static: false })
  wrongPasswordAttempt: ElementRef;
  @ViewChild('carouselBanner', { static: false })
  carouselBanner: ElementRef;
  @ViewChild('adfsCallbackModal', { static: false })
  adfsCallbackModal: ModalLoginComponent;
  @ViewChild('cloudServiceCallbackModal', { static: false })
  cloudServiceCallbackModal: ModalCloudServiceComponent;
  subscriptions = new Subscription();

  @Select((state) => state.auth?.remaining_attempts)
  loginFailed$: Observable<number>;
  remainingAttempts: number;
  queryParam: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private spinner: SpinnerService,
    private navbar: NavbarService,
    private alert: AlertService,
    public translate: TranslateService,
    private themeService: ThemeService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    public modalService: NgbModal,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
  ) {
    this.language = this.translate.currentLang;
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
    this.externalLink = featureFlag.close_feature
      ? this.externalLinkNew
      : this.externalLinkOld;
  }

  loginType = 'Login_Success';
  startTime = 0;
  endTime = 0;
  numberOfErrors = 0;

  OnFocusField() {
    if (window.performance) {
      this.startTime = window.performance.now();

      window.addEventListener(
        'beforeunload',
        this.OnClosePage.bind(this),
        false,
      );
    }
  }

  OnClosePage(event: Event) {
    this.endTime = window.performance.now();
    if (this.loginType === 'Login_Error') {
      gtag('event', this.loginType, {
        Time_Before_Exit:
          this.endTime - this.startTime
            ? ((this.endTime - this.startTime) / -1000).toFixed(2)
            : 0,
        Number_of_Errors: this.numberOfErrors,
      });
      window.removeEventListener('beforeunload', function () {});
    }
  }

  OnLoginSuccess() {
    this.loginType = 'Login_Success';
    gtag('event', 'Login_Success', {
      Time_On_Flow:
        this.endTime - this.startTime
          ? ((this.endTime - this.startTime) / -1000).toFixed(2)
          : 0,
      Number_of_Errors: this.numberOfErrors,
    });
    window.removeEventListener('beforeunload', function () {});
  }

  OnLoginFailed() {
    this.loginType = 'Login_Error';
    this.numberOfErrors += 1;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const queryparams = params['message'];
      const typeParams = params['type'];
      if (queryparams && !typeParams) {
        setTimeout(() => {
          this.adfsCallbackModal.open();
        }, 2000);
      }
      if (queryparams && typeParams === 'cloud-service') {
        setTimeout(() => {
          this.cloudServiceCallbackModal.open(queryparams, typeParams);
        }, 2000);
      }
    });

    this.logout();
    this.checkCurrentTime();
    this.activateRoute.queryParams.subscribe((res) => {
      this.queryParam = res;
    });
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
    this.navbar.setActiveSidebar(false);
    this.changeLanguage(this.language);

    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required],
    });
    this.isMobile = this.themeService.isMobile();
    this.checkResponsive();
    this.subscribeLoginFailed();
  }

  logout() {
    this.store.dispatch(ClearState);
  }

  ngAfterViewInit(): void {
    this.highlight();
    this.openBannerModal(this.carouselBanner);
  }

  onResize(event): void {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
    this.checkResponsive();
  }

  checkResponsive(): void {
    if (this.isMobile) {
      this.size = 'compact';
    } else {
      this.size = 'normal';
    }
  }

  checkCurrentTime(): void {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  adfsLogin(promptLogin = false): void {
    localStorage.setItem(
      'queryParam',
      JSON.stringify(this.queryParam),
    );
    window.location.href =
      ADFS_URL.login +
      '?email=' +
      this.email +
      '&prompt_login=' +
      promptLogin;
  }

  onSubmit(): void {
    this.submitted = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.spinner.hide();
      this.OnLoginFailed();
    }
    this.loading = true;
    this.store
      .dispatch(
        new Login({
          username: this.f.username.value,
          password: this.f.password.value,
          approvalParams: this.queryParam,
        }),
      )
      .subscribe(
        (response) => {
          if (
            !response.auth.is_set_password ||
            !response.auth.is_pass_t_c ||
            response.auth.is_password_expired
          ) {
            this.zone.run(() => {
              this.router.navigate(['/activate-account']).then(() => {
                location.reload();
              });
            });
            return;
          }

          if (
            !response.auth.otp_type &&
            (response.auth.role === 'General' ||
              response.auth.role === 'HR')
          ) {
            this.zone.run(() => {
              this.router.navigate(['/activate-account']).then(() => {
                location.reload();
              });
            });
            return;
          }
          if (this.queryParam.approval) {
            const approvalParams = this.queryParam;
            this.router
              .navigateByUrl(
                `/approval/${approvalParams.approval}?lang=${approvalParams.lang}`,
              )
              .then();
            return;
          }
          if (this.queryParam?.url) {
            const params = this.queryParam;
            this.router.navigateByUrl(`${params?.url}`).then();
            return;
          }
          if (
            response.auth.role !== 'General' &&
            response.auth.role !== 'HR'
          ) {
            this.zone.run(() => {
              this.router.navigate(['/users']);
            });
          } else {
            this.zone.run(() => {
              this.router.navigate(['/memos']);
            });
          }
          this.OnLoginSuccess();
        },
        (error) => {
          // error response
          this.OnLoginFailed();
          if (!error?.error?.remaining_attempts) {
            this.alert.error(
              error?.error?.detail ||
                this.translate.instant('ERROR.CONTACT-DEV'),
            );
          }
        },
      );
  }

  submitForgotPassword(): void {
    this.authenticationService
      .forgotPassword({
        email: this.email,
      })
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe(
        () => {
          this.forgotPasswordSuccess = true;
          this.textList = 'LOGIN.EMAIL-RESET-TEXT';
        },
        (error) => {
          if (error?.error?.is_ad_user === true) {
            // if user comes from ADFS Server, redirect user to ADFS login page.
            if (error?.error?.reset_password_url) {
              window.location.href = error?.error?.reset_password_url;
            } else {
              this.adfsLogin(true);
            }
          } else if (error?.error?.detail) {
            this.alert.error(error.error.detail);
          } else {
            this.alert.error(error.error.email.join());
          }
        },
      );
  }

  toggleForgetPassword(): void {
    this.modalService.dismissAll();
    this.forgotPasswordMode = true;
    this.loginMode = false;
    this.textList = 'LOGIN.PASS-WORK-RESET-TEXT';
    this.forgotPasswordSuccess = false;
  }

  changeLanguage(lang: string): void {
    this.language = lang;
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    }
    if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  gotoLogin(): void {
    this.loginMode = true;
    this.forgotPasswordMode = false;
    this.email = '';
    this.textList = '';
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
    // this.checkValidate = true;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  handleError(): void {}

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }

  subscribeLoginFailed(): void {
    this.subscriptions.add(
      this.loginFailed$.subscribe((res: number) => {
        if (res) {
          if (res <= 3 && !this.modalService.hasOpenModals()) {
            this.remainingAttempts = res;
            this.modalService.open(this.wrongPasswordAttempt, {
              centered: true,
              backdrop: 'static',
            });
          } else {
            this.alert.error(
              this.translate.instant(
                'LOGIN.INVALID-USERNAME-OR-PASSWORD',
              ),
            );
          }
        }
      }),
    );
  }

  redirectToExternalUrl(type): void {
    window.open(this.externalLink[type], '_blank');
  }

  redirectToInternalUrl(type): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/terms-and-conditions']),
    );
    window.open(url, '_blank');
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openBannerModal(content): void {
    this.modal = this.modalService.open(content, {
      backdrop: 'static',
      centered: true,
      windowClass: 'modal-banner',
    });
  }

  closeModal(): void {
    this.modal.close();
  }
}
