<ng-template #certificateStatusTemplate
    let-certStatus
    let-lawSection="lawSection">
  <div class="validity-status"
      [ngSwitch]="certStatus">
    <ng-container *ngSwitchCase="'valid'">
      <span class="fas fa-check-circle text-success"></span>
      <label class="text-success">{{ 'CERTIFICATE.VALID' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'expired'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.EXPIRED' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'revoked'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.REVOKED' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'not_created'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">
        <span *ngIf="lawSection === '26'; else defaultNotCreateWording">
          {{ 'CERTIFICATE.USER-NO-CERT-SYSTEM-CREATE' | translate }}
        </span>
        <ng-template #defaultNotCreateWording>
          <span>
            {{ 'CERTIFICATE.NOT-CREATED' | translate }}
          </span>
        </ng-template>
      </label>
    </ng-container>
  </div>
</ng-template>
<div class="radio-section mt-3">
  <div class="flex-lg-nowrap py-2">
    <div class="radio-option">
      <div class="icon-radio ml-2"
          (click)="radioOptionDdocSec26.click()">
        <span class="circle"></span>
        <span *ngIf="ddocLawSection === '26'"
            class="dot"></span>
      </div>
      <input #radioOptionDdocSec26
          class="d-none"
          id="radio-option-ddoc-sec-26"
          [name]="'radio-ddoc-section' + uniqueId"
          type="radio"
          value="26"
          [(ngModel)]="ddocLawSection"
          (ngModelChange)="onDdocLawSectionChange($event)">
      <label for="radio-option-ddoc-sec-26">
        {{ 'LOA.DIGITAL-SIGNATURE-SEC-26' | translate }}
      </label>
      <!-- TODO - Make it to popup and add content in future sprint -->
      <span class="fal fa-info-circle icon-info d-none d-md-inline invisible"></span>
    </div>
    <ng-container *ngTemplateOutlet="certificateStatusTemplate;
        context: { $implicit: certStatusSec26, lawSection: '26' }">
    </ng-container>
  </div>
  <div class="py-2">
    <div class="radio-option">
      <div class="icon-radio ml-2"
          (click)="radioOptionDdocSec28.click()">
        <span class="circle"></span>
        <span *ngIf="ddocLawSection === '28'"
            class="dot"></span>
      </div>
      <input #radioOptionDdocSec28
          class="d-none"
          id="radio-option-ddoc-sec-28"
          [name]="'radio-ddoc-section' + uniqueId"
          type="radio"
          value="28"
          [disabled]="certStatusSec28 !== 'valid'"
          [(ngModel)]="ddocLawSection"
          (ngModelChange)="onDdocLawSectionChange($event)">
      <label for="radio-option-ddoc-sec-28">
        {{ 'LOA.DIGITAL-SIGNATURE-SEC-28' | translate }}
      </label>
      <!-- TODO - Make it to popup and add content in future sprint -->
      <span class="fal fa-info-circle icon-info invisible"></span>
    </div>
    <ng-container *ngTemplateOutlet="certificateStatusTemplate; context: { $implicit: certStatusSec28 }">
    </ng-container>
  </div>
</div>