import { TokenDecoded } from 'src/app/store/auth/auth.model';
import jwt_decode from 'jwt-decode';
import { HttpResponse } from '@angular/common/http';

export const tokenExpired = (): boolean => {
  const exp = tokenDecoded()?.exp;
  return Math.floor(new Date().getTime() / 1000) >= exp;
};
export const tokenDecoded = (): TokenDecoded => {
  if (!localStorage.getItem('currentUser')) {
    return null;
  }
  return jwt_decode(localStorage.getItem('currentUser'));
};

export const lang = (): string => {
  return localStorage.getItem('lang');
};

export const createDownloadFile = (data, filename) => {
  const blob = new Blob([data], { type: data.type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

export const getFileNameFromResponse = (
  res: HttpResponse<Blob>,
): string => {
  console.log(res.headers);
  const contentDisposition = res.headers.get('Content-Disposition');
  const r = /filename=(.+);?/;
  const rawName = r.exec(contentDisposition)[1];
  return decodeURIComponent(rawName);
};

export const createDownloadFileFromResponse = (
  res: HttpResponse<Blob>,
) => {
  const blob = res.body;
  const filename = getFileNameFromResponse(res);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

type Lang = 'th' | 'en';
