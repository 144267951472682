import { Observable } from 'rxjs';
import { ApiService } from './../../../core/http/api.service';
import { Injectable } from '@angular/core';
import { API_URL } from 'src/app/shared/service/api.constant';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(private http: ApiService) {}

  fetchConfiguration(): Observable<any> {
    return this.http.get('/api/configurations/');
  }

  saveConfiguration(model: any): Observable<any> {
    return this.http.patch('/api/configurations/bulk-update/', model);
  }

  getPasswordPolicy(): Observable<any> {
    return this.http.get(`/api/pw-policy/`);
  }

  updatePasswordPolicy(data): Observable<any> {
    return this.http.patch(`/api/pw-policy/`, data);
  }

  resetTC(): Observable<any> {
    return this.http.post(API_URL.configuration + 'reset-tc/', null);
  }
}

export interface PasswordPolicy {
  is_needed_digit: boolean;
  is_needed_upper_case: boolean;
  is_needed_lower_case: boolean;
  is_needed_special_case: boolean;
  minimum_length: number;
  days_before_expire: number;
  times_before_can_reuse?: any;
  times_of_failed_attempt: number;
  minutes_before_session_timeout?: any;
  maximum_duplicate_character: number;
  minimum_days_before_reset?: any;
}
