import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl = environment.baseUrl;

  constructor(
    private spinner: SpinnerService,
    private http: HttpClient,
    private translate: TranslateService,
  ) {}

  requestHeader(): HttpHeaders {
    const lang =
      this.translate.currentLang || localStorage.getItem('lang');
    const headers = new HttpHeaders().set(
      'Accept-Language',
      lang || 'en',
    );
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return headers;
  }

  get<T>(url: string, params?: any, headers?: any): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, {
      headers: headers ? headers : this.requestHeader(),
      params: { ...params },
    });
  }

  getBlob<T>(url: string, params?: any): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, {
      headers: this.requestHeader(),
      params: { ...params },
      responseType: 'blob' as 'json',
    });
  }

  postBlob<T>(url: string, data?: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, data, {
      headers: this.requestHeader(),
      responseType: 'blob' as 'json',
    });
  }

  postBlobResponse(
    url: string,
    data?: any,
  ): Observable<HttpResponse<Blob>> {
    return this.http.post<Blob>(this.baseUrl + url, data, {
      headers: this.requestHeader(),
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  getMedia<T>(url: string, params?: any): Observable<T> {
    return this.http.get<T>(url, {
      headers: this.requestHeader(),
      params: { ...params },
      responseType: 'blob' as 'json',
    });
  }

  printFile<T>(url, params?): Observable<T> {
    return this.http.get<T>(url, {
      headers: this.requestHeader(),
      params: { ...params },
      responseType: 'blob' as 'json',
    });
  }

  post<T>(url: string, item: any, headers?: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, item, {
      headers: headers ? headers : this.requestHeader(),
    });
  }

  put<T>(url: string, item: any): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, item, {
      headers: this.requestHeader(),
    });
  }

  patch<T>(url: string, item: any): Observable<T> {
    return this.http.patch<T>(this.baseUrl + url, item, {
      headers: this.requestHeader(),
    });
  }

  delete<T>(url: string, item?: any): Observable<T> {
    return this.http.delete<T>(this.baseUrl + url, {
      headers: this.requestHeader(),
    });
  }

  download<T>(
    url: string,
    params?: { [type: string]: string },
  ): Observable<T> {
    return this.http.get<T>(url, {
      headers: this.requestHeader(),
      params: { ...params },
      responseType: 'blob' as 'json',
    });
  }

  pdfPost<T>(url: string, data?: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, data, {
      headers: this.requestHeader(),
    });
  }

  // Check Mobile Operating system
  getMobileOperatingSystem(): string {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/.test(userAgent)) {
      return 'Android';
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return null;
  }

  isMobileOperationSystem(): boolean {
    return this.getMobileOperatingSystem() != null;
  }

  openMediaUrlInNewWindow(mediaUrl) {
    if (mediaUrl == null) return;

    if (!(typeof mediaUrl === 'string' || mediaUrl instanceof String))
      console.error(
        'openMediaUrlInNewWindow accept string only. received: ',
        mediaUrl,
      );

    mediaUrl = mediaUrl.trim();
    if (mediaUrl.length == 0) return;

    // open new window here to prevent popup-block.
    //   default page will be MediaWindowComponent in file 'app-routing.module.ts'
    //   because mobile device cannot preview pdf and it will automatically download file.
    const newWindow = window.open('/window-media');
    this.getMedia(mediaUrl, { responseType: 'blob' }).subscribe(
      (response: Blob) => {
        // this will fix Adblock block new window.
        //   more info: https://stackoverflow.com/questions/43283454/open-blob-objecturl-in-chrome
        if (newWindow.document.readyState === 'complete') {
          newWindow.location = URL.createObjectURL(response);
          newWindow.focus();
        } else {
          newWindow.onload = () => {
            newWindow.location = URL.createObjectURL(response);
            newWindow.focus();
          };
        }
      },
    );
  }
}
