import { MarkerIdentity } from 'src/app/modules/memos/components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';

export class ActiveWidget {
  static readonly type = '[widget] active widget';
  constructor(public selected: MarkerIdentity) {}
}

export class CloseActive {
  static readonly type = '[widget] CloseActive';
}

export class IsEditWidget {
  static readonly type = '[widget] Is Edit Widget';
  constructor(public markerIdentityId: string) {}
}
