<ng-template #downloadModal>
  <div class="modal-header">
    <h5>{{ 'MEMOS.DOWNLOAD-DOCUMENT-FILES' | translate }}</h5>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body px-1 px-md-3 px-lg-5">
    <ul class="list-group">
      <!-- Level 1 -->
      <li class="list-group-item"
        [style.--c]="hoverBackgroundColor" [class.selected]="selectedAll">
        <input type="checkbox" class="cross-compatible" id="selectAll" [ngModel]="selectedAll" (change)="selectAll()">
        <label class="form-check-label pl-2" for="selectAll">
          {{ 'DASHBOARD.SELECT-ALL' | translate }}
        </label>
      </li>

      <!-- Level 2 -->
      <li class="list-group-item indent-1" *ngIf="options?.showRespondentCSV"
        [style.--c]="hoverBackgroundColor" [class.selected]="payload.include_respondents">
        <input type="checkbox" class="cross-compatible" id="respondent-file"
          [(ngModel)]="payload.include_respondents">
        <label class="form-check-label pl-2" for="respondent-file">
          {{ 'MEMOS.RESPONDENTS-FILE' | translate }}
        </label>
      </li>
      <li class="list-group-item indent-1" [class.text-muted]="options?.disableDocument"
        [style.--c]="hoverBackgroundColor" [class.selected]="payload.include_documents">
        <input type="checkbox" class="cross-compatible" id="document-file"
          [(ngModel)]="payload.include_documents" [disabled]="options?.disableDocument">
        <label class="form-check-label pl-2" for="document-file">
          {{ 'MEMOS.DOCUMENT-FILE' | translate }}
        </label>
      </li>
      <li class="list-group-item indent-1" [class.text-muted]="options?.disableCert"
        [style.--c]="hoverBackgroundColor" [class.selected]="payload.include_certs">
        <input type="checkbox" class="cross-compatible" id="cert-file"
          [(ngModel)]="payload.include_certs" [disabled]="options?.disableCert">
        <label class="form-check-label pl-2" for="cert-file">
          {{ 'MEMOS.CERT-OF-COMPLETION-FILE' | translate }}
        </label>
      </li>
      <li class="list-group-item indent-1" [class.text-muted]="!downloadInfos.length"
        [style.--c]="hoverBackgroundColor" [class.selected]="selectedAllAttachments">
        <input type="checkbox" class="cross-compatible" id="selectAllAttachments"
          [ngModel]="selectedAllAttachments" (click)="selectAllAttachments()" [disabled]="!downloadInfos.length">
        <label class="form-check-label pl-2" for="selectAllAttachments">
          {{ 'MEMOS.ALL-ATTACHMENTS' | translate }}
        </label>

        <div class="pointer collapse-arrow mr-2" data-toggle="collapse" data-target="#collapseAttachments"
          aria-expanded="true" aria-controls="collapseAttachments" (click)="toggleCollapse()" *ngIf="downloadInfos.length">
          <i class="fas fa-chevron-down" [class.d-none]="!attachmentsCollapsed"></i>
          <i class="fas fa-chevron-up" [class.d-none]="attachmentsCollapsed"></i>
        </div>
      </li>

      <div class="collapse show" id="collapseAttachments">
        <!-- Level 3 (1) -->
        <ng-container *ngIf="downloadInfos.length > 1">
          <ng-container *ngFor="let downloadInfo of downloadInfos; let i = index">
            <li class="list-group-item indent-2"
              [style.--c]="hoverBackgroundColor" [class.selected]="downloadInfo.selected">
              <input type="checkbox" class="cross-compatible" [id]="'memo' + i"
                [ngModel]="downloadInfo.selected" (change)="selectAllAttachmentsInMemo(i)">
              <label class="form-check-label pl-2" [for]="'memo' + i">
                {{ 'MEMOS.ATTACHMENT-OF' | translate }} {{ downloadInfo.memo_number || '(Draft)' }}
              </label>
            </li>

            <!-- Level 4 -->
            <li class="list-group-item indent-3" *ngFor="let attachment of downloadInfo.attachments; let j = index"
              [style.--c]="hoverBackgroundColor" [class.selected]="attachment.selected">
              <input type="checkbox" class="cross-compatible" [id]="'attachment' + j"
                [ngModel]="attachment.selected" (change)="selectAttachment(i, j)">
              <label class="form-check-label pl-2" [for]="'attachment' + j">
                {{ attachment.file_name }}
              </label>
            </li>
          </ng-container>
        </ng-container>

        <!-- Level 3 (2) -->
        <ng-container *ngIf="downloadInfos.length === 1">
          <li class="list-group-item indent-2" *ngFor="let attachment of downloadInfos[0].attachments; let j = index"
            [style.--c]="hoverBackgroundColor" [class.selected]="attachment.selected">
            <input type="checkbox" class="cross-compatible" [id]="'attachment' + j"
              [ngModel]="attachment.selected" (change)="selectAttachment(0, j)">
            <label class="form-check-label pl-2" [for]="'attachment' + j">
              {{ attachment.file_name }}
            </label>
          </li>
        </ng-container>
      </div>
    </ul>
  </div>
  <div class="modal-footer">
    <div class="row justify-content-center px-2">
      <button class="btn p-btn btn-outline-yale-blue mx-2"
          (click)="close()">
        {{ 'MEMOS.CANCEL' | translate }}
      </button>

      <button class="btn p-btn mx-2"
          type="button"
          appSetTheme
          (click)="onConfirmClick()"
          [disabled]="!canSubmit">
        {{ 'MEMOS.DOWNLOAD' | translate }}
      </button>
    </div>
  </div>
</ng-template>
