const url = window.location.href;
const matches = url.match(/^http(s?)?\:\/\/([^\/?#]+)/);
const baseUrl = matches[0];
const websocketUrl = baseUrl.replace('http', 'ws') + '/ws/';

export const environment = {
  production: false,
  baseUrl,
  websocketUrl,
};

export const featureFlag = {
  ddoc: false,
  ndid: false,
  maintenance_mode: false,
  maintenance_message_th: '',
  maintenance_message_en: '',
  sms_otp: false,
  email_otp: false,
  broadcast: true,
  mailandsign: true,
  close_feature: false,
};

export const sso = {
  cpfconnect: {
    url: 'https://dev-h1-staging.ekoapp.com',
    response_type: 'code',
    client_id: '7be5c707-db23-4be7-a47e-e9c54c8d8e6c',
    redirect_uri: 'https://sign-uat.axonstech.com/login/sso/callback',
    scope: 'openid profile',
    state: 'eko-cpf',
    contact_us: 'https://helpdesk.axonstech.com/',
  },
};

export const cloudService = {
  appId: 'axons-sign-local',
  baseUrl: 'https://api.uat.axonscloud.com',
  defaultTenant: 'cpf-test',
  storage: 'localStorage',
};
