import { Injectable } from '@angular/core';
import Swal, {
  SweetAlertIcon,
  SweetAlertResult,
} from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private translate: TranslateService) {}

  success(
    title: string = this.translate.instant('MEMOS.SAVE-CHANGES'),
    timer = 3000,
  ): void {
    Swal.fire({
      toast: true,
      position: 'top-end',
      imageUrl: 'assets/gif/success_loading.gif',
      imageWidth: 40,
      imageHeight: 40,
      imageAlt: 'Custom image',
      title: title || 'Success',
      showConfirmButton: false,
      timer: timer || 3000,
    });
  }

  error(title = 'Error', timer = 3000): void {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: title || 'Error',
      showConfirmButton: false,
      showCancelButton: true,
      timer: 10000,
      cancelButtonText: ' <i class="fas fa-times"></i>',
      customClass: {
        cancelButton: 'custom-cancel-alert',
      },
    });
  }

  errorCustomIcon(
    title = 'Error',
    message?: string,
    timer = 10000,
  ): void {
    const html = `
    <div class="d-flex flex-nowrap align-items-center" style="gap: 16px;">
        <div class="ml-3">
          <img src="assets/svg/login/icon-brake-warning-fill.svg" alt="icon" style="width: 24px; height: 24px;">
        </div>
        <div>
          <div class="font-weight-bold my-2 text-left text-danger" style="font-size: 16px">${title}</div>
          <div class="text-left mb-1" style="font-size: 14px">${message}</div>
        </div>
    </div>
    `;
    Swal.fire({
      toast: true,
      position: 'top-end',
      html: html || 'Error',
      showConfirmButton: false,
      showCancelButton: true,
      timer,
      cancelButtonText: ' <i class="fas fa-times"></i>',
      background: '#F8E5E5',
      customClass: {
        cancelButton: 'custom-cancel-alert-center',
        title: 'custom-title-alert-left',
        popup: 'p-1 px-1 pb-2 custom-popup-maxwidth shadow-none',
      },
    });
  }

  errorDetail(title: string | HTMLElement | JQuery, html): void {
    Swal.fire({
      icon: 'error',
      title,
      showConfirmButton: true,
    });
  }

  confirm(
    title: string = this.translate.instant('ALERT.Are you sure'),
    text: string = this.translate.instant(
      'ALERT.You want to save your changes',
    ),
    icon: SweetAlertIcon = 'warning',
    confirmButtonText: string = this.translate.instant(
      'ALERT.Confirm',
    ),
    showCancelButton = true,
    cancelButtonText: string = this.translate.instant('ALERT.Cancel'),
  ): Promise<
    SweetAlertResult<{
      isConfirmed: boolean;
      isDismissed: boolean;
      value: boolean;
    }>
  > {
    return Swal.fire({
      title,
      text,
      icon,
      showCancelButton,
      confirmButtonText,
      cancelButtonText,
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
    });
  }

  permission(): Promise<SweetAlertResult<unknown>> {
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: ' No permission to access!',
    });
  }
}
