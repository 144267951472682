import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from '../../../shared/service/api.constant';

@Injectable({
  providedIn: 'root',
})
export class CertificateService {
  constructor(private http: ApiService) {}

  getCertificateList(data?: { [type: string]: string }) {
    return this.http.get(API_URL.certificate, data);
  }

  getCertificateListById(id) {
    return this.http.get(`/api/certificate/${id}/`);
  }

  createCertificate(data) {
    return this.http.post(API_URL.certificate, data);
  }

  deleteCertificate(id) {
    return this.http.delete(API_URL.certificate + id + '/');
  }

  revokeCertificate(id, data) {
    return this.http.post(
      API_URL.certificate + id + '/revoke/',
      data,
    );
  }

  getCerPermission(id) {
    return this.http.get(
      `/api/certificate/${id}/get-cert-permission/`,
    );
  }

  undoRevokeCertificate(id) {
    return this.http.get(API_URL.certificate + id + '/undo-revoke/');
  }

  updateSignature(id, data) {
    return this.http.post(
      `/api/certificate/${id}/update-organization-signature/`,
      data,
    );
  }

  updateCertificate(id, data) {
    return this.http.post(
      `/api/certificate/${id}/update-cert-permission/`,
      data,
    );
  }

  updateSealingCert(id, data) {
    return this.http.post(`/api/certificate/${id}/sealing/`, data);
  }

  getSealing() {
    return this.http.get(`/api/certificate/sealing/`);
  }
}
