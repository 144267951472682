<div class="container">
  <div class="content-frame">
    <div class="bg"
        *ngIf="!recall">
      <div class="content row">
        <div class="col-lg-12 col-md-12">
          <img *ngIf="!hasPermission"
              src="assets/images/404.svg"
              class="mb-5 justify-content-end">
          <img *ngIf="hasPermission"
              src="assets/images/permission_denied.svg"
              class="mb-5 justify-content-end">
          <h3 class="mt-2">{{hasPermission ? 'Permission Denied' : 'Page not found...'}}</h3>
          <h5 class="mt-2"> {{message}}</h5>
          <div class="text-center mt-4">
            <button class="btn btn-primary-dark"
                (click)="goToLogin()">
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 text-center bg-recall"
        *ngIf="recall">
      <div class="content row custom-position">
        <div class="col-lg-12 col-md-12">
          <img src="assets/images/recall_for_revise.svg"
              class="mb-5 justify-content-end size-image">
          <h4 class="mt-2 f-w-600">Memo was recalled for revising</h4>
          <p class="mt-2 font-italic f-w-500">เอกสารถูกเรียกกลับไปแก้ไข</p>

          <p class="mt-4 mb-0 f-w-500">You cannot access this document. The requester retrieve this memo to edit</p>
          <p class="mt-2 font-italic small font-weight-light">
            คุณไม่สามารถเข้าถึงเอกสารนี้ได้ เนื่องจากผู้สร้างเอกสารเรียกเอกสารฉบับนี้กลับไปแก้ไข
          </p>
          <div class="text-center mt-4">
            <button class="btn btn-primary-dark"
                [routerLink]="['/login']">
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>