import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import {
  ClearState,
  GetUserById,
  Login,
  LoginSSo,
  Logout,
  SaveUserDetail,
} from './auth.actions';
import {
  AuthResponse,
  AuthStateModel,
  UserDetail,
} from './auth.model';

import { tokenDecoded } from 'src/app/shared/utils/utils';
import { Observable } from 'rxjs';
import { DropdownService } from '../../shared/service/dropdown.service';
import { NavbarService } from '../../core/components/navbar/navbar.service';
import { AxonsCloudService } from 'src/app/shared/service/cloud-service';

declare const gtag: Function;

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    refresh: null,
    access: null,
    username: null,
    first_name: null,
    first_name_th: null,
    last_name: null,
    photo: null,
    id: null,
    person_id: null,
    signature: null,
    departments: null,
    job_position: null,
    is_set_password: null,
    is_pass_t_c: null,
    role: null,
    otp_type: null,
    phone_number: null,
    email: null,
    is_custom: null,
    is_password_expired: null,
    emailUrl: null,
    previous_login: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class AuthState {
  constructor(
    private authService: AuthenticationService,
    private dropdownService: DropdownService,
    private spinner: SpinnerService,
    private alert: AlertService,
    private translate: TranslateService,
    private store: Store,
    private navbarService: NavbarService,
    private axonsCloudService: AxonsCloudService,
  ) {}

  @Selector()
  static token(state: AuthStateModel): AuthStateModel {
    return state;
  }

  setUserDataToStorage(result: AuthResponse): void {
    localStorage.setItem('username', result.username);
    localStorage.setItem('photoUrl', result.photo || '');
    localStorage.setItem('currentUser', result.access);
    localStorage.setItem('pid', result.person_id + '');
    localStorage.setItem('refresh', result.refresh.replace(`"`, ''));
    localStorage.setItem(
      'is_cloud_service',
      result?.is_cloud_service ? 'true' : 'false',
    );
    localStorage.setItem(
      'is_set_password',
      result.is_set_password.toString(),
    );
    localStorage.setItem(
      'is_password_expired',
      result.is_password_expired.toString(),
    );
    localStorage.setItem(
      'is_pass_t_c',
      result.is_pass_t_c.toString(),
    );
    localStorage.setItem(
      'otp_type',
      result.otp_type ? result.otp_type.toString() : '',
    );
    localStorage.setItem('lang', 'en');
    // Gtag: Properties
    if (result?.id) {
      gtag('set', 'user_properties', {
        User_ID: result?.id,
      });
    }
    // END Gtag: Properties
  }

  @Action(Login)
  login({ setState }: StateContext<AuthStateModel>, { payload }) {
    this.spinner.show();
    return this.authService
      .login(payload.username, payload.password)
      .pipe(
        tap((result) => {
          this.setUserDataToStorage(result);
          result.role = result.role.replace(
            /[&\/\\#,+()[\]$~%.'":*?<>{}]/g,
            '',
          );
          this.store.dispatch(GetUserById);
          setState({
            ...result,
          });
        }),
        catchError((error) => {
          if (error?.error?.remaining_attempts) {
            setState({
              remaining_attempts: error?.error?.remaining_attempts,
            });
          }
          throw error;
        }),
        finalize(() => this.spinner.hide()),
      );
  }

  @Action(LoginSSo)
  loginSSo({ setState }: StateContext<AuthStateModel>, { payload }) {
    this.spinner.show();
    return this.authService.loginSSo(payload.code, payload.type).pipe(
      tap((result) => {
        this.setUserDataToStorage(result);
        result.role = result.role.replace(
          /[&\/\\#,+()[\]$~%.'":*?<>{}]/g,
          '',
        );
        this.store.dispatch(GetUserById);
        setState({
          ...result,
        });
      }),
      catchError((error) => {
        if (error?.error?.remaining_attempts) {
          setState({
            remaining_attempts: error?.error?.remaining_attempts,
          });
        }
        throw error;
      }),
      finalize(() => this.spinner.hide()),
    );
  }

  @Action(Logout)
  logout({ setState }: StateContext<AuthStateModel>): AuthStateModel {
    const isCloudService = localStorage.getItem('is_cloud_service') === 'true';

    if (localStorage.getItem('currentUser')) {
      this.authService.logout().subscribe();
    }
    const keysToKeep = ['bannerClosed', 'resetBannerTime'];
    for (const key in localStorage) {
      if (
        localStorage.hasOwnProperty(key) &&
        !keysToKeep.includes(key)
      ) {
        localStorage.removeItem(key);
      }
    }
    this.navbarService.setActiveSidebar(false);

    if (isCloudService) {
      localStorage.removeItem('is_cloud_service');
      this.axonsCloudService?.clearTokens?.();
      this.axonsCloudService?.logout?.();
    }

    return setState(null);
  }

  @Action(ClearState)
  clearState({
    setState,
  }: StateContext<AuthStateModel>): AuthStateModel {
    if (localStorage.getItem('currentUser')) {
      this.authService.logout().subscribe();
    }
    const keysToKeep = ['bannerClosed', 'resetBannerTime'];
    for (const key in localStorage) {
      if (
        localStorage.hasOwnProperty(key) &&
        !keysToKeep.includes(key)
      ) {
        localStorage.removeItem(key);
      }
    }
    return setState(null);
  }

  @Action(GetUserById)
  getUser({
    setState,
    getState,
  }: StateContext<AuthStateModel>):
    | Observable<UserDetail>
    | Promise<boolean> {
    const state = getState();
    const pid = state?.person_id
      ? state.person_id
      : localStorage.getItem('pid');
    return this.authService
      .getPersonDetail(pid ? +pid : tokenDecoded().user_id)
      .pipe(
        tap((user) => {
          setState({
            refresh: localStorage.getItem('currentUser'),
            access: localStorage.getItem('currentUser'),
            username: user.user.username,
            first_name: user.user.first_name,
            first_name_th: user.user.first_name_th,
            last_name: user.user.last_name,
            last_name_th: user.user.last_name_th,
            photo: user.photo,
            id: user.user.id,
            is_ad_user: user.is_ad_user,
            is_set_password: user.is_set_password,
            is_pass_t_c: user.is_pass_t_c,
            person_id: user.id,
            departments: user.departments_id,
            job_position: user.job_position,
            role: user.role.name,
            signature: user.signature,
            otp_type: user.otp_type,
            phone_number: user.phone_number,
            phone_number_e164number: user.phone_number_e164number,
            phone_number_country_code: user.phone_number_country_code,
            email: user.user.email,
            previous_login: user.previous_login,
            preferred_timezone: user.preferred_timezone,
            preferred_timezone_hours: user.preferred_timezone_hours,
          });
        }),
      );
  }

  @Action(SaveUserDetail)
  saveItem(
    { setState, getState }: StateContext<AuthStateModel>,
    { value, name }: { value: any; name: string },
  ): void {
    const state = getState();

    setState({
      ...state,
      [name]: value,
    });
  }
}
