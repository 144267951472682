<div
  class="d-flex justify-content-center align-items-center"
  style="max-width: 600px; height: 100vh"
>
  <div
    class="d-flex justify-content-center"
    style="border-radius: 15px"
  >
    <div
      class="
        d-flex
        flex-column
        justify-content-center
        align-items-center
        gap-4
        text-center
      "
    >
      <img
        src="assets/images/do-not-enter-sign.png"
        alt="Image"
        style="width: 350px; object-fit: cover"
      />
      <div class="d-flex flex-column mt-3" style="gap: 5px">
        <div class="h3 text-primary">
          {{ title }}
        </div>
        <div class="text-secondary">
          {{ message }}
        </div>
        <div class="m-4">
          <button
            class="btn btn-primary login-bt w-100"
            style="height: 43px; min-width: 345px; max-width: 345px"
            (click)="buttonClick()"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
