import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { NavbarService } from '../navbar/navbar.service';
import { versionInfo } from '../../../../version-info';
import { SettingService } from 'src/app/modules/setting/shared/setting.service';
import { AlertService } from '../../services/alert.service';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss'],
})
export class TermsPageComponent implements OnInit {
  themeList: ThemeList;
  language = 'en';

  externalLink = {
    // eslint-disable-next-line max-len
    faq: 'https://docs.google.com/presentation/d/1ybZh_c4hYrDII9K4Hr-DLGAjnsxFfl2G/edit?usp=sharing&ouid=117556539342367831444&rtpof=true&sd=true',
    feedback:
      ' https://docs.google.com/forms/d/e/1FAIpQLSd5T9uXjUHA4gXOAKr-2F1u2EHEmKGZxeTtQRDv5ZvnNf71Rw/viewform?usp=pp_url',
    // eslint-disable-next-line max-len
    help: 'https://docs.google.com/presentation/d/1rbYFQMpnXyslhtQYbQw_f1p2KrmETEMt/edit?usp=sharing&ouid=117556539342367831444&rtpof=true&sd=true',
    policy: ' https://www.cpfworldwide.com/th/privacynotice',
  };

  version = versionInfo.hash;
  checkValidate = false;
  isMobile;
  currentTime: Date;
  termsDetail = {
    T_AND_C_EN: null,
    T_AND_C_TH: null,
  };

  public size: 'compact' | 'normal' = 'compact';
  public lang = 'en';
  constructor(
    public translate: TranslateService,
    private themeService: ThemeService,
    private navbar: NavbarService,
    private spinner: SpinnerService,
    private alert: AlertService,
    private settingService: SettingService,
  ) {
    this.language = this.translate.currentLang;
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnInit(): void {
    this.navbar.setActiveSidebar(false);
    this.changeLanguage(this.language);
    this.checkCurrentTime();
    this.getTerms();
  }

  getTerms(): void {
    this.spinner.show();
    this.settingService.fetchConfiguration().subscribe(
      (res: any) => {
        this.spinner.hide();
        res.results.forEach((detail) => {
          if (
            detail.key === 'T_AND_C_EN' ||
            detail.key === 'T_AND_C_TH'
          ) {
            this.termsDetail[detail.key] = detail.value;
          }
        });
      },
      (error) => {
        this.spinner.hide();
      },
    );
  }

  redirectToExternalUrl(type): void {
    window.open(this.externalLink[type], '_blank');
  }

  changeLanguage(lang: string): void {
    this.language = lang;
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    }
    if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  onResize(event): void {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
    this.checkResponsive();
  }

  checkResponsive(): void {
    if (this.isMobile) {
      this.size = 'compact';
    } else {
      this.size = 'normal';
    }
  }

  checkCurrentTime(): void {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }
}
