<ng-template #deleteConfirmationModal
    let-c="close"
    let-d="dismiss">
  <div class="modal-header red">
    <h4 class="modal-title">
      {{header|translate}}
    </h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body text-center">
    <h4 *ngIf="type !== 'text-template'">{{text|translate}}</h4>
    <ng-container *ngIf="type === 'text-template'">
      <h5>{{'TEMPLATE.TEXT' | translate}}</h5>
      <div [style.color]="'#747474'"
          [style.font-size.px]="'13'">{{"TEMPLATE.TEXT-2"|translate}}</div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button class="btn p-btn btn-outline btn-w-80"
        [style.--c]="themeList?.header_table_color"
        (click)="c()">
      {{'MEMOS.CANCEL'|translate}}
    </button>
    <button class="btn p-btn btn-danger btn-w-80"
        *ngIf="type !== 'text-template'"
        (click)="submit()">
      {{'MEMOS.DELETE'|translate}}
    </button>
    <button *ngIf="type === 'text-template'"
    class="btn p-btn"
        appSetTheme
        (click)="submit()"
        type="button">
      {{'CREATE-USER.CONFIRM'|translate}}
    </button>
  </div>
</ng-template>

<i *ngIf="type==='icon' && !useNewDesign"
    class="fas fa-trash icon-btn red p-0"
    (click)="$event.stopPropagation(); open(deleteConfirmationModal)">
  <i class="fas fa-trash"
      *ngIf="!useNewDesign"></i>
</i>

<svg-icon *ngIf="type==='icon' && useNewDesign"
    class="size-svg pointer"
    src="assets/svg/delete.svg"
    (click)="open(deleteConfirmationModal)"></svg-icon>

<!-- <img class="pointer" *ngIf="type==='icon' && useNewDesign" src="assets/images/icons/delete.svg"
     (click)="open(deleteConfirmationModal)"> -->

<button *ngIf="type === 'text'"
    class="btn btn-danger p-btn"
    (click)="open(deleteConfirmationModal)">
  {{ deleteMessage|translate }}
</button>

<button *ngIf="type === 'text-w-100'"
    class="btn btn-danger w-100"
    (click)="open(deleteConfirmationModal)">
  {{ deleteMessage|translate }}
</button>

<button *ngIf="type === 'text-memo'"
    class="btn btn-danger del-btn w-auto"
    (click)="open(deleteConfirmationModal)">
  {{ "MEMOS.DELETE" | translate }}
</button>


<button *ngIf="type === 'broadcast'"
    class="btn btn-danger del-btn w-auto"
    (click)="open(deleteConfirmationModal)">
  {{ screenWidth < 576
      ?
      ("MEMOS.DELETE"|translate):
      ("BROADCAST.Delete Broadcast"|translate)
      }}
      </button>

    <button *ngIf="type === 'text-template'"
        class="btn btn-danger del-btn w-auto"
        (click)="open(deleteConfirmationModal)">
      {{ screenWidth < 576
          ?
          ("MEMOS.DELETE"|translate):
          ("TEMPLATE.Delete Template"|translate)
          }}
          </button>

        <div *ngIf="type === 'x-icon'"
            (click)="open(deleteConfirmationModal)">
          <i class="fas fa-times pointer"></i>
        </div>

        <button *ngIf="type === 'tag'"
            class="tag red pointer"
            (click)="open(deleteConfirmationModal)">
          {{ "CREATE-USER.DELETE"|translate }}
        </button>