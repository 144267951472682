import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { OtpSmsService } from '../../../modules/profile/component/setting-otp-sms/otp-sms.service';
import { SetRef } from '../../../store/otp/otp.actions';
import { AlertService } from '../../../core/services/alert.service';
import { OtpMailService } from '../../../modules/profile/component/setting-otp-mail/otp-mail.service';
import { getHiddenPhoneNumber } from '../../utils/common.util';
import * as moment from 'moment';
import { SpinnerService } from 'src/app/core/services/spinner.service';

declare const gtag: Function;

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss'],
})
export class OtpModalComponent implements OnInit {
  @Input() isSaved: boolean;
  @Input() otp: string;
  @Output() otpChange = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter<void>();
  @Input() header;

  @ViewChild('otpGoogleAuthen', { static: false })
  otpGoogleAuthen: ElementRef;
  @ViewChild('otpEmail', { static: false })
  otpEmail: ElementRef;
  @ViewChild('otpPhoneNumber', { static: false })
  otpPhoneNumber: ElementRef;

  timeLeft = 300;
  interval;

  otpType: 'sms' | 'email' | 'totp' = 'totp';
  userPhoneNumber: string;
  userPhoneNumberCountryCode: string;
  userEmail: string;

  refDisPlay: string;
  isLoadingOTP: boolean;

  constructor(
    public modalService: NgbModal,
    private store: Store,
    private otpSmsService: OtpSmsService,
    private otpMailService: OtpMailService,
    private spinner: SpinnerService,
    private alert: AlertService,
  ) {}

  ngOnInit(): void {
    this.store.subscribe((s) => {
      this.otpType = s.auth?.otp_type;
      this.userPhoneNumber = s.auth?.phone_number;
      this.userPhoneNumberCountryCode =
        s.auth?.phone_number_country_code;
      this.userEmail = s.auth?.email;
    });
  }

  onSaveGtag = () => {
    // Gtag
    const GtagCreateDocument = localStorage.getItem(
      'Doc_Approved_Sign',
    );
    if (GtagCreateDocument) {
      const gtagLocalStorage = JSON.parse(GtagCreateDocument);
      if (gtagLocalStorage.Start_Time) {
        const parseStartTime = gtagLocalStorage.Start_Time;
        var a = moment(moment().format());
        var b = moment(parseStartTime);
        const diffSeconds = a.diff(b, 'seconds');
        if (gtagLocalStorage.Type_Sign === "Upload" || gtagLocalStorage.Type_Sign === "Sign_Now" || gtagLocalStorage.Type_Sign === "Typeing" || gtagLocalStorage.Type_Sign === "Stored") {
          gtag('event', 'Doc_Approved_Sign', {
            Time: diffSeconds,
            Document_Preview_Scroll:
              gtagLocalStorage?.Document_Preview_Scroll,
            Pagination_Click: gtagLocalStorage?.Pagination_Click,
            Preview_Mode: gtagLocalStorage?.Preview_Mode,
            Type_Sign: gtagLocalStorage?.Type_Sign,
          });
        } 
        
        localStorage.removeItem('Doc_Approved_Sign');
      }
    }
    // End Gtag
  };

  getHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', this.header);
  }

  openModal(): void {
    if (this.otpType === 'totp') {
      this.openTOTP();
      return;
    }
    this.sendOTP();
  }

  openTOTP() {
    this.modalService.open(this.otpGoogleAuthen, {
      backdrop: 'static',
      centered: true,
    });
  }

  sendOTP() {
    this.isLoadingOTP = true;
    this.spinner.show();
    let header = null;
    if (this.header) {
      header = this.getHeader();
    }
    const service =
      this.otpType === 'sms'
        ? this.otpSmsService
        : this.otpMailService;
    service.send(null, header).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.refDisPlay = res.ref_display;
        this.store.dispatch(
          new SetRef({
            ref: res.ref,
            refDisplay: this.refDisPlay,
          }),
        );
        this.isLoadingOTP = false;

        if (this.otpType === 'sms') {
          this.openSMSOTP();
        } else {
          this.openEmailOTP();
        }
      },
      (error) => {
        this.spinner.hide();
        this.isLoadingOTP = false;
        if (error.status === 400 && error.error) {
          const firstKey = Object.keys(error.error)[0];
          try {
            const errorObj = JSON.parse(error.error[firstKey]);
            if ('detail' in errorObj) {
              this.alert.error(errorObj.detail);
            } else if ('message' in errorObj) {
              this.alert.error(errorObj.message);
            } else {
              this.alert.error(error.error[firstKey]);
            }
          } catch (e) {
            this.alert.error(error.error[firstKey]);
          }
        } else {
          this.alert.error(error.status);
        }
      },
    );
  }

  openSMSOTP() {
    this.modalService.open(this.otpPhoneNumber, {
      backdrop: 'static',
      centered: true,
    });

    this.startTimer();
  }

  openEmailOTP() {
    this.modalService.open(this.otpEmail, {
      backdrop: 'static',
      centered: true,
    });

    this.startTimer();
  }

  onOtpChange(event): void {
    if (event.length === 6) {
      this.otp = event;
    } else {
      this.otp = null;
    }
  }

  startTimer(): void {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer(): void {
    clearInterval(this.interval);
  }

  resend(): void {
    this.isLoadingOTP = true;
    let header = null;
    if (this.header) {
      header = this.getHeader();
    }
    const service =
      this.otpType === 'sms'
        ? this.otpSmsService
        : this.otpMailService;
    service.send(null, header).subscribe(
      (res: any) => {
        this.refDisPlay = res.ref_display;
        this.store.dispatch(
          new SetRef({
            ref: res.ref,
            refDisplay: this.refDisPlay,
          }),
        );
        this.isLoadingOTP = false;
        this.pauseTimer();
        this.timeLeft = 300;
        this.startTimer();
        this.otp = null;
      },
      (error) => {
        this.isLoadingOTP = false;
        if (error.status === 400 && error.error) {
          const firstKey = Object.keys(error.error)[0];
          try {
            const errorObj = JSON.parse(error.error[firstKey]);
            if ('detail' in errorObj) {
              this.alert.error(errorObj.detail);
            } else if ('message' in errorObj) {
              this.alert.error(errorObj.message);
            } else {
              this.alert.error(error.error[firstKey]);
            }
          } catch (e) {
            this.alert.error(error.error[firstKey]);
          }
        } else {
          this.alert.error(error.status);
        }
      },
    );
  }

  close(): void {
    this.modalService.dismissAll();
    this.otp = null;
    this.closeModal.emit();
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  get invalidOTP(): boolean {
    return !this.otp || String(this.otp).length < 6;
  }

  get hiddenNumber(): string {
    return getHiddenPhoneNumber(
      this.userPhoneNumber,
      this.userPhoneNumberCountryCode,
    );
  }

  get hiddenEmail(): string {
    if (!this.userEmail) {
      return '';
    }
    return (
      this.userEmail.slice(0, 4) +
      '****@' +
      this.userEmail.split('@')[1]
    );
  }
}
