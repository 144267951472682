import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { UploadMemoTypeService } from '../../service/upload-memo-type.service';
import * as _ from 'lodash';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { FolderList } from 'src/app/core/components/sidebar/sidebar.component';
import { featureFlag } from 'src/environments/environment';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  closeNewFeature = featureFlag.close_feature;

  @Input() boundaryLinks: boolean;
  @Input() count: number;
  @Input() ellipses: boolean;
  @Input() rotate: boolean;
  @Input() pageSize: number;
  @Input() newDesign = false;
  @Input() maxSize = 5;
  @Output() pageSizeChange = new EventEmitter();

  @Input() page: number;
  @Output() pageChange = new EventEmitter();
  @Input() noneSize: boolean;
  @Input() isChecked;
  @Input() canDeleted: boolean;
  @Input() canReturn: boolean;
  @Output() generateExcel = new EventEmitter();
  @Output() downloadMemo = new EventEmitter();
  @Output() trashMemo = new EventEmitter();
  @Output() returnMemo = new EventEmitter();
  @Output() permanentlyDeleteMemo = new EventEmitter();
  @Output() downloadAllBroadcastMemo = new EventEmitter();
  @Input() downloadButton = false;
  @Input() countCheck: number;

  @Input() isBroadcast = false;
  @Output() resentBroadcast = new EventEmitter();
  @Input() broadcastList;
  @Output() broadcastListChange = new EventEmitter();
  @Input() textBtn;
  @Input() checkStatusApproved = false;
  @Input() alwaysShowDownloadAll = false;
  @Input() isTemplate = false;
  @Input() isFolder = false;
  @Output() trashMemoTemplate = new EventEmitter();
  @Output() moveFolderDetail = new EventEmitter();
  @Output() createFolderDetail = new EventEmitter();
  @Output() clickFolder = new EventEmitter();
  @Output() deleteFolderDetail = new EventEmitter();
  @Input() isClickFolder = false;
  @Input() folderList: FolderList[] = [];
  @Input() isMemosList = false;
  @Input() isLoadingCsv;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('inputFolderName') private inputFolderName: ElementRef;
  isMobile = this.themeService.isMobile();
  themeList: ThemeList;
  paginationDropdown = ['10', '20', '30', '40', '50'];
  modal: NgbModalRef;

  listTypeResendBtn = [];
  typeResend = null;
  showListMenu = false;
  listBtn = [];
  isMoveFolder = false;
  keepMemoId: number;
  folderName = 'Folder';
  searchFolder: string;
  isTablet = false;

  constructor(
    private themeService: ThemeService,
    private modalService: NgbModal,
    private service: UploadMemoTypeService,
    private memoService: MemoService,
  ) {
    this.isTablet = this.themeService.isPortrait();
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
    this.service.setResendTypeMemo.subscribe((res) => {
      this.convertBroadcast(res);
    });

    this.memoService.checkFolderChange.subscribe(() => {
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    });
  }

  convertBroadcast(data): void {
    this.showListMenu = false;
    let value = null;
    if (data.length > 1) {
      const selectedMemo = data
        ?.filter((obj) => obj.checked)
        ?.map((obj) => obj);
      this.listTypeResendBtn = selectedMemo;
      this.listTypeResendBtn = _.uniqBy(
        this.listTypeResendBtn,
        'status',
      );
    } else {
      if (data.checked) {
        this.listTypeResendBtn.push(data);
        this.listTypeResendBtn = _.uniqBy(
          this.listTypeResendBtn,
          'status',
        );
      } else if (this.listTypeResendBtn.length > 0) {
        value = this.listTypeResendBtn
          ?.filter((obj) => obj.checked)
          ?.map((obj) => obj);
        value = value.filter((e) => e?.status);
        this.listTypeResendBtn = _.uniqBy(value, 'status');
      }
    }
  }

  openModalResend(content, type?): void {
    this.typeResend = type;
    this.modal = this.modalService.open(content, { size: 'md' });
  }

  changePageSizeEmit(e) {
    this.page = 1;
    this.pageSize = e;
    this.pageSizeChange.emit(this.pageSize);
  }

  changePageEmit() {
    this.pageChange.emit(this.page);
  }

  onDownloadMemo(): void {
    this.downloadMemo.emit();
  }

  onTrashMemo(): void {
    this.trashMemo.emit();
  }

  onTrashTemplate(): void {
    this.trashMemoTemplate.emit();
  }

  onReturnMemo(): void {
    this.returnMemo.emit();
  }

  onPermanentlyDelete(): void {
    this.permanentlyDeleteMemo.emit();
  }

  onDownloadAllBroadcastMemo(): void {
    this.downloadAllBroadcastMemo.emit();
  }

  generateReportExcel(): void {
    this.generateExcel.emit();
  }

  openModal(content): void {
    this.modal = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      centered: true,
    });
  }

  confirmResend(): void {
    this.resentBroadcast.emit(this.typeResend);
  }

  openMoveFolder(memo) {
    this.isMoveFolder = true;
  }

  getFolderList(): void {
    this.memoService.getFolder().subscribe((res: FolderList[]) => {
      this.folderList = res;
    });
  }

  openMoveFolderModal(folder, dropdown): void {
    dropdown.close();
    this.moveFolderDetail.emit(folder);
  }

  openDeleteFolderModal(): void {
    this.deleteFolderDetail.emit();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  createFolder(content): void {
    this.clickFolder.emit();
    this.folderName = 'Folder';
    if (this.isTablet) {
      this.modal = this.modalService.open(content, {
        size: 'md',
        backdrop: 'static',
        centered: true,
      });
    }
    setTimeout(() => {
      this.inputFolderName.nativeElement.focus();
      this.scrollToBottom();
    }, 100);
  }

  enterCreateFolder(event, dropdown) {
    this.createFolderDetail.emit(this.folderName);
  }

  clickOutside(event, dropdown): void {
    const target = event.target as HTMLElement;
    const classList = target?.classList;
    if (
      // ng-select option clicked
      classList?.contains('item-folder') ||
      target?.id === 'check'
    ) {
      return;
    } else {
      this.isMoveFolder = false;
      this.isClickFolder = false;
      this.folderName = 'Folder';
      dropdown?.close();
    }
  }

  filterItems() {
    return this.folderList.filter((item) => {
      if (this.searchFolder == null) {
        return item;
      } else {
        return item.name
          .toLowerCase()
          .includes(this.searchFolder?.toLowerCase());
      }
    });
  }

  close(): void {
    this.folderName = null;
    this.modalService.dismissAll();
  }

  openMoveDropdown(): void {
    this.isTablet = this.themeService.isPortrait();
  }

  onResize(event, dropdown): void {
    dropdown?.close();
  }
  // get canReturn(): boolean {
  //   return this.status === 'trashed';
  // }
}
