import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/http/api.service';

@Pipe({
  name: 'secure',
})
export class SecurePipe implements PipeTransform {
  constructor(
    private http: ApiService,
    private sanitizer: DomSanitizer,
  ) {}

  isValidHttpUrl(text: string): boolean {
    let url;
    try {
      url = new URL(text);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  transform(url: string, defaultImage = true): Observable<SafeUrl> {
    if (!url) {
      return null;
    }
    if (!this.isValidHttpUrl(url)) {
      return new Observable<string>((observer) => {
        observer.next(url);
      });
    }

    return new Observable<string>((observer) => {
      // This is a tiny blank image
      if (defaultImage) {
        observer.next(
          'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
        );
      }

      // The next and error callbacks from the observer
      const { next, error } = observer;

      this.http
        .getMedia(url, { responseType: 'blob' })
        .subscribe((response) => {
          const reader = new FileReader();
          reader.readAsDataURL(response as Blob);
          reader.onloadend = () => {
            observer.next(reader.result as string);
          };
        });

      return {
        unsubscribe() {},
      };
    });
  }
}
