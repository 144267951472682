import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  NgbDate,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';

export interface DatePicker {
  fromDate: NgbDate;
  toDate: NgbDate;
  fromDateFormat: string;
  toDateFormat: string;
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  hoveredDate: NgbDate | null = null;

  @Input() date: DatePicker;
  @Output() dateChange = new EventEmitter();

  @Output() eventClearDate = new EventEmitter();
  @Output() eventSearchDate = new EventEmitter();

  constructor(private formatter: NgbDateParserFormatter) {}

  onDateSelection(date: NgbDate) {
    if (!this.date.fromDate && !this.date.toDate) {
      this.date.fromDate = date;
      this.date.fromDateFormat = this.formatter.format(date);
    } else if (
      this.date.fromDate &&
      !this.date.toDate &&
      date &&
      date.after(this.date.fromDate)
    ) {
      this.date.toDate = date;
      this.date.toDateFormat = this.formatter.format(date);
    } else {
      this.date.toDate = null;
      this.date.toDateFormat = null;
      this.date.fromDate = date;
      this.date.fromDateFormat = this.formatter.format(date);
    }
    this.dateChange.emit(this.date);
    this.eventSearchDate.emit();
  }

  isHovered(date: NgbDate) {
    return (
      this.date.fromDate &&
      !this.date.toDate &&
      this.hoveredDate &&
      date.after(this.date.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      this.date.toDate &&
      date.after(this.date.fromDate) &&
      date.before(this.date.toDate)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.date.fromDate) ||
      (this.date.toDate && date.equals(this.date.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  getShowDate() {
    if (this.date.fromDate) {
      return (
        this.formatter.format(this.date.fromDate) +
        ' -> ' +
        this.formatter.format(this.date.toDate)
      );
    }
    return null;
  }

  clearDate() {
    this.date.fromDate = null;
    this.date.toDate = null;
    this.date.fromDateFormat = null;
    this.date.toDateFormat = null;
    this.eventClearDate.emit();
  }
}
