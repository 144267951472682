import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AxpKit,
  AxpKitOptions,
  AuthStateSession,
  NineDotWidgetContext,
} from '@axons-library/portal-js-sdk';
import { cloudService } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AxonsCloudService {
  private axpKit: AxpKit | null = null;
  private authStateSubject =
    new BehaviorSubject<AuthStateSession | null>(null);
  private loadingSubject = new BehaviorSubject<boolean>(true);
  private isSilentLoginInProgress = false;

  nineDotAction: NineDotWidgetContext;
  openNineDot: boolean = false;

  authState$: Observable<AuthStateSession | null> =
    this.authStateSubject.asObservable();
  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor() {
    this.init();
  }

  async init() {
    const options: AxpKitOptions = {
      baseUrl: cloudService.baseUrl,
      storage: cloudService.storage as 'localStorage' | 'cookie',
      defaultTenant: cloudService.defaultTenant,
    };

    this.axpKit = AxpKit.load(cloudService.appId, options);

    this.axpKit?.onActiveAuthStateChanged(async (authState) => {
      if (!authState || !authState.sessions) {
        console.warn('authState or authState.sessions is undefined');
        this.authStateSubject.next(null);
        this.loadingSubject.next(false);
        return;
      }

      const activeSession = authState.sessions.find(
        (session) =>
          session.sessionKey === authState.activeSessionKey,
      );

      this.authStateSubject.next(activeSession || null);
      this.loadingSubject.next(false);

      if (activeSession?.state === 'valid') {
        this.fetchApplications();
      } else if (!this.isSilentLoginInProgress) {
        await this.silentLogin();
      }
    });

    try {
      await this.axpKit.init();
    } catch (error) {
      console.error('Error during AxpKit initialization:', error);
      this.loadingSubject.next(false);
    }
  }

  loginWithRedirect() {
    this.axpKit?.loginWithRedirectUrl(window.location.href);
  }

  async logout() {
    await this.axpKit?.logout(this.axpKit.getCurrentTenantUrl());
    this.authStateSubject.next(null);
  }

  async fetchApplications() {
    try {
      const response = await this.axpKit?.listApplications();
      // console.log('Applications:', response?.applications);
      return response?.applications || [];
    } catch (error) {
      console.error(
        '[AxonsCloudService] fetchApplications error:',
        error,
      );
    }
  }

  async silentLogin(sessionKey?: string) {
    if (this.isSilentLoginInProgress) return;
    this.isSilentLoginInProgress = true;
    this.loadingSubject.next(true);

    try {
      const session = await this.axpKit?.ssoSilent(sessionKey);
      this.authStateSubject.next(session);
    } catch (error) {
      console.error('Silent login failed:', error);
    } finally {
      this.loadingSubject.next(false);
      this.isSilentLoginInProgress = false;
    }
  }

  getAuthSession() {
    return this.authStateSubject.value;
  }

  async clearTokens() {
    const sessionKey = this.authStateSubject.value?.sessionKey;
    if (sessionKey) {
      await this.axpKit?.clearToken(sessionKey);
    }
  }

  async initNineDotWidget() {
    if (this.axpKit) {
      this.nineDotAction = await this.axpKit?.initNineDotWidget(
        'ninedot-component',
      );
    } else {
      console.warn('AxpKit is not initialized');
    }
  }

  toggleNineDot() {
    if (this.axpKit) {
      if (this.openNineDot) {
        this.nineDotAction.close();
        this.openNineDot = false;
      } else {
        this.nineDotAction.show();
        this.openNineDot = true;
      }
    } else {
      console.warn('AxpKit is not initialized');
      this.openNineDot = false;
    }
  }
}
