export const FONT_LIST = [
  { name: 'CordiaNew', value: 'TFSrivichai' },
  { name: 'CordiaNewBold', value: 'TFSrivichaiBold' },
  { name: 'CordiaNewItalic', value: 'TFSrivichaiItalic' },
  { name: 'CordiaNewBoldItalic', value: 'TFSrivichaiBoldItalic' },
  { name: 'AngsanaNew', value: 'TFPimpakarn' },
  { name: 'AngsanaNewBold', value: 'TFPimpakarnBold' },
  { name: 'AngsanaNewItalic', value: 'TFPimpakarnItalic' },
  { name: 'AngsanaNewBoldItalic', value: 'TFPimpakarnBoldItalic' },
  { name: 'THSarabunNew', value: 'THSarabunNew' },
  { name: 'THSarabunNewBold', value: 'THSarabunNewBold' },
  { name: 'THSarabunNewItalic', value: 'THSarabunNewItalic' },
  { name: 'THSarabunNewBoldItalic', value: 'THSarabunNewBoldItalic' },
];

export const DATE_TYPE_LIST = [
  {
    value: 'published_at',
    label: 'Publish Date',
    th: 'วันที่เผยแพร่',
  },
  {
    value: 'finished_at',
    label: 'Finished Date',
    th: 'วันที่เสร็จสิ้น',
  },
  {
    value: 'custom_date',
    label: 'Custom Date',
    th: 'กำหนดวันที่เอง',
  },
];

export const SIGNATURE_COLOR_LIST = [
  '#000000',
  '#4169e1',
  '#EB2B36',
  '#266D1F',
  '#CECECE',
];

export const NUMBER_FORMAT_LIST = [
  {
    label: 'Number',
    value: 1,
    th: 'ตัวเลข',
  },
  {
    label: 'Currency',
    value: 2,
    th: 'ค่าเงิน',
  },
  {
    label: 'Phone Number',
    value: 3,
    th: 'เบอร์โทรศัพท์',
  },
];

export const NUMBER_LIST = [
  {
    label: '1000',
    value: 1,
  },
  {
    label: '1,000.12',
    value: 2,
  },
  {
    label: '10.12%',
    value: 3,
  },
];

export const CURRENCY_LIST = [
  {
    label: '$1000',
    value: 1,
  },
  {
    label: '$1,000.12',
    value: 2,
  },
  {
    label: '1000 USD',
    value: 3,
  },
  {
    label: '1,000.12 USD',
    value: 4,
  },
  {
    label: '฿1000',
    value: 5,
  },
  {
    label: '฿1,000.12',
    value: 6,
  },
  {
    label: '1000 THB',
    value: 7,
  },
  {
    label: '1,000.12 THB',
    value: 8,
  },
];

export const TELEPHONE_NUMBER_LIST = [
  {
    label: '0812345678',
    value: 1,
  },
  {
    label: '081-234-5678',
    value: 2,
  },
  {
    label: '+XXX',
    value: 3,
  },
];

export const TITLE_LIST = [
  'Mr.',
  'Ms.',
  'Mrs.',
  'นาย',
  'นางสาว',
  'นาง',
  'เด็กหญิง',
  'เด็กชาย',
];

export const EMAIL_PATTERN =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
