import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss'],
})
export class ModalLoginComponent implements OnInit {
  @ViewChild('adfsCallbackModal', { static: true })
  adfsCallbackModal: ElementRef;
  constructor(public modalService: NgbModal) {}

  ngOnInit(): void {}

  open() {
    this.modalService.open(this.adfsCallbackModal, {
      backdrop: true,
      centered: true,
      size: 'sm',
      keyboard: false,
      windowClass: 'adfs-modal-dialog',
    });
  }
  closeAdfsModal(): void {
    this.modalService.dismissAll();
  }
}
