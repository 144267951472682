import { MarkerIdentity } from 'src/app/modules/memos/components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';

export class OpenTool {
  static readonly type = '[checkBox] Open Tool';
  constructor(public selected: MarkerIdentity) {}
}

export class CloseTool {
  static readonly type = '[checkBox] CloseTool';
}
