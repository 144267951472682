<ng-template #cloudServiceCallbackModal>
  <div class="p-3">
    <div
      class="d-flex justify-content-end"
      type="button"
      (click)="closeCloudServiceModal()"
    >
      <svg-icon
        src="assets/svg/login/icon-close-modal.svg"
        [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
        [stretch]="true"
      >
      </svg-icon>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <svg-icon
        src="assets/svg/login/icon-brake-warning.svg"
        [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"
        [stretch]="true"
      >
      </svg-icon>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <p class="f-18 font-weight-bold">
        {{ title }}
      </p>
    </div>
    <div class="d-flex justify-content-center mt-2">
      <p
        class="f-16 font-weight-normal text-center"
        [ngStyle]="{
          color: '#515151'
        }"
      >
        {{ message }}
      </p>
    </div>
    <div class="d-flex justify-content-center mt-1">
      <button
        (click)="closeCloudServiceModal()"
        class="
          btn btn-light
          font-weight-normal
          w-100
          f-16
          pt-2
          pb-2
          pl-3
          pr-3
        "
        [ngStyle]="{
          'border-radius': '8px',
          'background': themeList?.button_login_color ?? '#3750A1',
          'color': themeList?.text_button_login_color ?? '#FFFFFF'
        }"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</ng-template>
