import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';
import { MarkerIdentity } from '../../modules/memos/components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';
import { Injectable } from '@angular/core';
import { ActiveWidget, CloseActive, IsEditWidget } from './widget';

export class WidgetStateModel {
  public selected: MarkerIdentity;
  public markerIdentityId: string;
}

const DRAWING_STATE_TOKEN = new StateToken<WidgetStateModel>(
  'widget',
);

@State<WidgetStateModel>({
  name: DRAWING_STATE_TOKEN,
  defaults: {
    selected: null,
    markerIdentityId: '',
  },
})
@Injectable({
  providedIn: 'root',
})
export class WidgetState {
  constructor() {}

  @Selector()
  static selected(state: WidgetStateModel) {
    return state.selected;
  }

  @Action(ActiveWidget)
  activeWidget(
    ctx: StateContext<WidgetStateModel>,
    action: ActiveWidget,
  ) {
    ctx.patchState({
      selected: action.selected,
    });
  }

  @Action(CloseActive)
  CloseTool(
    ctx: StateContext<WidgetStateModel>,
    action: CloseActive,
  ) {
    ctx.patchState({
      selected: null,
    });
  }

  @Action(IsEditWidget)
  isEditWidget(
    ctx: StateContext<WidgetStateModel>,
    action: IsEditWidget,
  ) {
    ctx.patchState({
      markerIdentityId: action.markerIdentityId,
    });
  }
}
