import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL } from 'src/app/shared/service/api.constant';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ApproveService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {}

  requestHeader(headers: HttpHeaders): HttpHeaders {
    const lang =
      this.translate.currentLang || localStorage.getItem('lang');
    headers = headers.append('Accept-Language', lang || 'en');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    return headers;
  }

  requestHeaderForm(headers: HttpHeaders): HttpHeaders {
    const lang =
      this.translate.currentLang || localStorage.getItem('lang');
    headers = headers.append('Accept-Language', lang || 'en');
    headers = headers.append('Accept', 'application/json');
    headers.set('Content-Type', 'multipart/form-data');
    return headers;
  }

  getUserDetailWithHeader(header) {
    return this.http.get(environment.baseUrl + API_URL.me, {
      headers: this.requestHeader(header),
    });
  }

  verifyOTPAuthWithHeader(data, header) {
    return this.http.post(
      environment.baseUrl + `/api/email-otp/verify-auth/`,
      data,
      {
        headers: this.requestHeader(header),
      },
    );
  }

  getMemoDetail(id) {
    return this.http.get(
      environment.baseUrl + API_URL.memos + id + '/',
    );
  }

  getChoices(id) {
    return this.http.get(
      environment.baseUrl +
        '/api/broadcast-memos/' +
        id +
        '/respond-choices/',
    );
  }

  getMemoVersion(params) {
    return this.http.get(
      environment.baseUrl + API_URL.memos + 'version/',
      {
        params,
      },
    );
  }

  approveMemo(id, data?) {
    return this.http.post(
      environment.baseUrl + API_URL.general_memo + id + '/approve/',
      data,
    );
  }

  rejectMemo(id, data?) {
    return this.http.post(
      environment.baseUrl + API_URL.general_memo + id + '/reject/',
      data,
    );
  }

  terminateMemo(id, data?) {
    return this.http.post(
      environment.baseUrl + API_URL.general_memo + id + '/terminate/',
      data,
    );
  }

  getUserDetail() {
    return this.http.get(environment.baseUrl + API_URL.me);
  }

  getAttachmentList(params) {
    return this.http.get(
      environment.baseUrl + API_URL.memo_attachment,
      {
        params,
      },
    );
  }

  getCommentList(params) {
    return this.http.get(environment.baseUrl + API_URL.memo_comment, {
      params,
    });
  }

  getReferenceList(id) {
    return this.http.get(
      environment.baseUrl + API_URL.memos + id + '/references/',
    );
  }

  getMemoHistory(params) {
    return this.http.get(
      environment.baseUrl + API_URL.memos_history,
      {
        params,
      },
    );
  }

  createNewComment(data) {
    return this.http.post(
      environment.baseUrl + API_URL.memo_comment,
      data,
    );
  }

  deleteComment(id) {
    return this.http.delete(
      environment.baseUrl + API_URL.memo_comment + id + '/',
    );
  }

  // Announcement
  getAnnouncementList(header, params?) {
    return this.http.get(environment.baseUrl + '/api/announcement/', {
      params,
      headers: this.requestHeader(header),
    });
  }

  ack(id, header, data?) {
    return this.http.post(
      environment.baseUrl + '/api/acknowledges/' + id + '/ack/',
      data,
      {
        headers: this.requestHeader(header),
      },
    );
  }

  fetchConfiguration(header, params?) {
    return this.http.get(
      environment.baseUrl + '/api/configurations/',
      {
        headers: this.requestHeader(header),
      },
    );
  }

  saveTerms(header, data?) {
    return this.http.post(
      environment.baseUrl + `/api/people/tc/`,
      data,
      {
        headers: this.requestHeader(header),
      },
    );
  }
}
