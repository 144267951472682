<ng-select [items]="people$ | async"
    *ngIf="!refreshDropdown"
    [bindLabel]="translate.currentLang === 'en'? 'full_name': 'full_name_th'"
    [loading]="peopleLoading"
    placeholder="{{'MEMOS.SPECIFY-APPROVER' | translate}}"
    [disabled]="disabled"
    [(ngModel)]="approval"
    (change)="onDropdownChange($event)"
    (search)="search()"
    (close)="closeDropdown()"
    [typeahead]="peopleInput$"
    [minTermLength]="2"
    typeToSearchText="{{ 'user.type-search' | translate }}"
    [notFoundText]="'SELECT.No items found'|translate">
</ng-select>
<ng-template #certificateStatusTemplate>
  <div class="validity-status"
      [ngSwitch]="certificateStatus">
    <ng-container *ngSwitchCase="'valid'">
      <span class="fas fa-check-circle text-success"></span>
      <label class="text-success">{{ 'CERTIFICATE.VALID' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'expired'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.EXPIRED' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'not_created'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.NOT-CREATED' | translate }}</label>
    </ng-container>
  </div>
</ng-template>
<ng-container *ngIf="ddocEnable">
  <app-signing-law-section-radio [signerId]="approval?.person"
      [(ddocLawSection)]="ddocLawSection"
      (ddocLawSectionChange)="ddocLawSectionChange.emit($event)">
  </app-signing-law-section-radio>
</ng-container>