import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const isLoggedIn = localStorage.getItem('currentUser');
    if (
      isLoggedIn &&
      !request.headers.has('Authorization') &&
      !request.url.includes('theme-config-active')
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem(
            'currentUser',
          )}`,
        },
      });
      // Pass the cloned request instead of the original request to the next handle
    }

    if (
      localStorage.getItem('JWTKey') &&
      this.router.url.includes('/approval/') &&
      !request.headers.has('Authorization') &&
      !request.url.includes('theme-config-active')
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: localStorage.getItem('JWTKey'),
        },
      });
    }
    return next.handle(request);
  }
}
