<nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom custom-layer" id="navbar"
  [ngStyle]="{'background': 'linear-gradient(90deg, '+themeList?.bg_color_secondary+', '+themeList?.bg_color_primary+')'}">
  <div class="pointer mx-2 hidden-hamburger"
    [ngStyle]="{'color': themeList?.text_color}">
    <i class="custom-hamburger fa fa-bars" aria-hidden="true" (click)="sidebar()"></i>
  </div>
  <div class="row w-100">
    <div class="col px-0">
      <div class="row w-100 mx-0 justify-content-end">
        <div class="col-auto d-flex px-0 align-items-center">
          <div ngbDropdown class="d-inline-block px-2">
            <button class="btn btn-lang p-0"
              (mouseover)="hoverText('lang', true)"
              (mouseleave)="hoverText('lang', false)"
              [ngStyle]="{'color': hoverList.lang ? themeList?.hovered_text_color: themeList?.text_color}"
              id="dropdownLang" data-toggle="dropdown">
              <em class="fa fa-globe fa-lg"></em>
              <span class="pl-1 d-none d-md-inline">{{ language === 'th' ? 'TH' : 'EN' }}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownLang" class="lang-menu">
              <button ngbDropdownItem (click)="changeLanguage('en')"
                [style.backgroundColor]="language === 'en' ? themeList?.text_color : null">
                EN
              </button>
              <button ngbDropdownItem (click)="changeLanguage('th')"
                [style.backgroundColor]="language === 'th' ? themeList?.text_color : null">
                TH
              </button>
            </div>
          </div>

          <div class="noti-center" (clickOutside)="closeNotification()">
            <i class="fa fa-bell fa-lg noti-icon" aria-hidden="true" (mouseover)="hoverText('notification', true)"
              (mouseleave)="hoverText('notification', false)" (click)="openNotification()"
              [ngStyle]="{'color': hoverList.notification? themeList?.hovered_text_color: themeList?.text_color}"></i>
            <span class="badge-number d-inline-block" *ngIf="notificationResult && notificationResult.unread_count > 0">
              {{notificationResult.unread_count}}
            </span>
            <div *ngIf="showNotificationToggle" class="width-box">
              <div class="arrow"></div>
              <div class="custom-container ">
                <div class="row justify-content-between align-items-center px-2 w-title">
                  <h6 class="m-0 headerText-custom">{{notificationHeader}}</h6>
                  <a href="javascript:void(0)" style="color: inherit;"
                    (click)="markAllAsRead()">{{"NAV-BAR.MARK-ALL-AS-READ"|translate}}</a>
                </div>
                <div class="scroll row p-0" (scrollingFinished)="loadMoreNotification()" appScrollTracker>
                  <div *ngIf="notificationResult.results.length === 0"
                    class="row mx-auto px-1 pt-1 height-box border-bottom-custom col-12 d-flex justify-content-center">
                    <p style="font-size: 16px" class="pt-3">{{"NAV-BAR.NO-NOTIFICATION"|translate}}</p>
                  </div>
                  <div class="row mx-0 px-1 pt-1 height-box pointer border-bottom-custom col-12"
                    *ngFor="let notification of notificationResult.results" [ngClass]="{'bg-unread': !notification.read}"
                    (click)="clickNotification(notification)">
                    <div class="col-auto p-0 d-flex align-items-center mx-2">
                      <img [src]="(notification?.notification?.actor_photo | secure | async) || 'assets/images/flaticons/megaphone.png'"
                          class="photo-actor">
                    </div>
                    <div class="col p-0">
                      <p class="custom-box-detail"
                        [innerHTML]="translateSidebar.currentLang === 'th'? notification.content: notification.content_en">
                      </p>
                      <span>
                      <i class="fa fa-file-text mr-1 color-blue" aria-hidden="true"></i>
                      <i class="fa fa-comment mr-1 color-green" aria-hidden="true"></i>
                      <a class="text-time">{{ notification.notification.time_since }}</a>
                    </span>
                    </div>
                  </div>
                  <div class="w-100" style="margin: 5px">
                    <app-loading-spinner [loading]="isLoading"></app-loading-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row w-100 mx-0 justify-content-end">
        <div class="col-auto d-flex px-0 mt-1 align-items-end">
            <small class="last-login"
                [style.--c]="themeList?.text_color">
            {{ 'SIDEBAR.LAST-LOGIN' | translate }}
            <span *ngIf="profile?.previous_login">
              {{ profile.previous_login | date: 'dd/MM/yyyy HH:mm' : profile.preferred_timezone_hours ?
                 profile.preferred_timezone_hours : ''}}</span>
            <span *ngIf="!profile?.previous_login">-</span>
          </small>
        </div>
      </div>
    </div>

    <div class="col-auto md:col-auto ml-3 pr-lg-0 align-self-center profile-border"
      [style.--c]="themeList?.text_color">
      <div class="text-white d-flex" ngbDropdown display="dynamic">
        <div class="d-none d-md-inline ml-1 mr-4">
          <div [style.color]="themeList?.text_color">
            {{ translate.currentLang === 'en' ? profile?.first_name : profile?.first_name_th ? profile?.first_name_th :
            profile?.first_name}}
          </div>
          <div class="font-italic pointer" (click)="logOut()"
            style="font-size: 10px;text-decoration: underline" [style.color]="themeList?.text_color">Logout
          </div>
        </div>
        <img [src]="(profile?.photo | secure | async) || 'assets/images/person2.png'" (click)="navigateToProfile()"
            class="display-profile pointer d-none d-md-inline-block"
            [ngStyle]="{'border-color': themeList?.bg_color_secondary}">
        <img [src]="(profile?.photo | secure | async) || 'assets/images/person2.png'"
            class="display-profile pointer d-inline-block d-md-none"
            [ngStyle]="{'border-color': themeList?.bg_color_secondary}"
            id="dropdownProfile" data-toggle="dropdown">
        <div ngbDropdownMenu aria-labelledby="dropdownProfile" class="lang-menu dropdown-profile" style="right:0">
          <button ngbDropdownItem routerLinkActive="active" [routerLink]="['/', 'profile-detail', 'detail']">Profile</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem (click)="logOut()">Logout</button>
        </div>

        <div *ngIf="activeNinedot" class="mx-4 align-self-center">
          <app-widget-ninedot></app-widget-ninedot>
        </div>
      </div>
    </div>
  </div>
</nav>
