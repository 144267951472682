<div class="custom-box row">
  <div class="col-auto country-selection" style="padding-right: 5px">
    <ng-select
        [items]="allCountries"
        [(ngModel)]="selectedCountry"
        (ngModelChange)="onCountrySelect($event, focusable)"
        bindLabel="name"
        [clearable]="false"
        [readonly]="disabled"
        [disabled]="disabled"
    >
      <ng-template ng-label-tmp
        let-item="item">
        <div class="iti__flag-box">
          <div class="iti__flag"
              [ngClass]="item.flagClass"></div>
        </div>
        <span class="iti__dial-code">+{{item.dialCode}}</span>
      </ng-template>
      <ng-template ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm">
        <div class="iti__flag-box">
          <div class="iti__flag"
              [ngClass]="item.flagClass"></div>
        </div>
        <span class="iti__country-name">{{item.name}}</span>
        <span class="iti__dial-code">+{{item.dialCode}}</span>
      </ng-template>
    </ng-select>
  </div>
  <div class="col-sm col-12 pl-sm-0 pl-3 mt-3 mt-sm-0">
    <input type="tel"
        autocomplete="off"
        [id]="inputId"
        [ngClass]="cssClass"
        (blur)="onTouched()"
        (keypress)="onInputKeyPress($event)"
        [(ngModel)]="phoneNumber"
        (ngModelChange)="onPhoneNumberChange()"
        [readonly]="disabled"
        [disabled]="disabled"
        [placeholder]="resolvePlaceholder()"
        [attr.maxLength]="maxLength"
      #focusable>
  </div>
</div>