import { LoaLevel } from './memo.model';

export class SaveMemo {
  static readonly type = '[Memo] SaveMemo';

  constructor(public payload: any) {}
}

export class GetMemoData {
  static readonly type = '[Memo] GetMemoData';
}

export class SaveItemMemo {
  static readonly type = '[Memo] SaveItemMemo';

  constructor(public value: any, public name: string) {}
}

export class CreateMemo {
  static readonly type = '[Memo] CreateMemo';

  constructor(public payload: any) {}
}

export class UpdateMemo {
  static readonly type = '[Memo] UpdateMemo';

  constructor(public id: number, public payload: any) {}
}

export class AddAttachFile {
  static readonly type = '[Memo] AddAttachFile';

  constructor(public file: File) {}
}

export class AddCustomLoaLevel {
  static readonly type = '[Memo Customizer] AddCustomLoaLevel';

  constructor(public name: string) {}
}

export class RemoveCustomLoaLevel {
  static readonly type = '[Memo Customizer] RemoveCustomLoaLevel';

  constructor(public index?: number) {}
}

export class UpdateCustomLoaLevel {
  static readonly type = '[Memo Customizer] UpdateCustomLoaLevel';

  constructor(
    public index: number,
    public updatedLoaLevel: LoaLevel,
  ) {}
}

export class ResetCustomLoa {
  static readonly type = '[Memo Customizer] ResetCustomLoa';

  constructor() {}
}

export class ResetState {
  static readonly type = '[Memo] ResetState';

  constructor() {}
}

export class UploadFile {
  static readonly type = '[Memo] UploadFileMemo';

  constructor(
    public id: number,
    public changePage: boolean,
    public isTemplate?: boolean,
  ) {}
}

export class RemoveFile {
  static readonly type = '[Memo] RemoveFileMemo';

  constructor(public id: number) {}
}

export class UploadPDFMemo {
  static readonly type = '[Memo] UploadPDFMemo';

  constructor(public id: number) {}
}

export class ErrorNotification {
  static readonly type = '[Memo] SetError Notification';

  constructor(public error: any) {}
}

export class PreviewMemo {
  static readonly type = '[Memo] Preview Memo';

  constructor(public payload: any) {}
}
