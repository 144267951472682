import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../services/alert.service';
import { SSO_URL } from '../../http/api.constant';

@Component({
  selector: 'app-modal-cloud-service',
  templateUrl: './modal-cloud-service.component.html',
  styleUrls: ['./modal-cloud-service.component.scss'],
})
export class ModalCloudServiceComponent implements OnInit {
  @ViewChild('cloudServiceCallbackModal', { static: true })
  cloudServiceCallbackModal: ElementRef;
  constructor(
    public modalService: NgbModal,
    public translate: TranslateService,
    private router: Router,
    private alert: AlertService,
  ) {}

  title: string = '';
  message: string = '';
  buttonText: string = '';
  buttonLink: string = '';
  buttonLinkExternal: boolean = false;

  modalActionMessage: string[] = [
    'User does not exist',
    'User is disabled',
  ];

  ngOnInit(): void {}

  open(message?: string, type?: string) {
    this.initialModalMessage();

    if (type === 'cloud-service') {
      this.setCloudServiceModalMessage(message);
    }

    if (this.modalActionMessage.includes(message)) {
      this.modalService.open(this.cloudServiceCallbackModal, {
        backdrop: true,
        centered: true,
        size: 'sm',
        keyboard: false,
        windowClass: 'cloud-service-modal-dialog',
      });
    } else {
      this.alertMessage(this.title, this.message);
    }
  }

  closeCloudServiceModal(): void {
    if (this.buttonLink) {
      if (this.buttonLinkExternal) {
        window.open(this.buttonLink, '_blank');
      } else {
        this.router.navigateByUrl(this.buttonLink);
      }
    }

    this.title = '';
    this.message = '';
    this.buttonText = '';
    this.buttonLinkExternal = false;
    this.modalService.dismissAll();
  }

  initialModalMessage(): void {
    this.title = this.translate.instant('LOGIN.INCORRECT-USER');
    this.message = this.translate.instant(
      'LOGIN.PLEASE-FILL-OUT-THE-INFORMATION',
    );
    this.buttonText = this.translate.instant('LOGIN.TRY-AGAIN');
    this.buttonLink = '';
  }

  setCloudServiceModalMessage(message: string): void {
    switch (message) {
      case 'User does not exist':
        this.title = this.translate.instant('SSO.Account not found');
        this.message = this.translate.instant(
          'SSO.Account not found - detail',
        );
        this.buttonText = this.translate.instant('SSO.Contact us');
        this.buttonLink = SSO_URL.cpfconnect.contactUs;
        this.buttonLinkExternal = true;
        break;
      case 'User is disabled':
        this.title = this.translate.instant('SSO.Account disabled');
        this.message = this.translate.instant(
          'SSO.Account disabled - detail',
        );
        this.buttonText = this.translate.instant('SSO.Contact us');
        this.buttonLink = SSO_URL.cpfconnect.contactUs;
        this.buttonLinkExternal = true;
        break;
      case 'Failed to obtain token from OAuth server':
        this.title = this.translate.instant(
          'SSO.Authorization failed',
        );
        this.message = this.translate.instant(
          'SSO.Authorization failed - detail',
        );
        this.buttonText = '';
        this.buttonLink = '';
        break
      default:
        this.title = this.translate.instant(
          'SSO.Authentication error',
        );
        this.message = this.translate.instant(
          'SSO.Authentication error - detail',
        );
        this.buttonText = '';
        this.buttonLink = '';
        break;
    }
  }

  alertMessage(title: string, message: string): void {
    this.alert.errorCustomIcon(title, message);
  }
}
