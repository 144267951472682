<div class="position-relative p-5">
  <h4 class="mb-5">
    {{ 'PROFILE.Setting Signature with Typing' | translate }}
  </h4>
  <div class="row">
    <div class="col-12" [class.col-md-6]="!onlyFullName">
      <div class="form-group mb-4">
        <label class="d-block">{{
          'PROFILE.Full Name' | translate
        }}</label>
        <input
          class="form-control"
          [readOnly]="selectOnly"
          [(ngModel)]="_fullName"
          (ngModelChange)="onFullNameChange($event, fullNameCanvas)"
        />
      </div>
      <div class="canvas-group mb-4">
        <label class="d-block col-12 px-0" for="canvas-fullname">
          {{ 'PROFILE.Full Name Example' | translate }}
        </label>
        <canvas
          #fullNameCanvas
          class="canvas-signature col-12"
          id="canvas-fullname"
          height="140"
          [class.selected]="selectedSignature === 'full'"
          (click)="onTextSignatureSelect('full')"
        >
        </canvas>
      </div>
    </div>
    <div *ngIf="!onlyFullName" class="col-12 col-md-6">
      <div class="form-group mb-4">
        <label class="d-block">{{
          'PROFILE.Initials' | translate
        }}</label>
        <input
          class="form-control"
          [readOnly]="selectOnly"
          [(ngModel)]="_abbreviatedName"
          (ngModelChange)="
            redrawTextSignature($event, abbreviatedNameCanvas)
          "
        />
      </div>
      <div class="canvas-group mb-4">
        <label
          class="d-block px-0 col-12"
          for="canvas-abbreviated-name"
        >
          {{ 'PROFILE.Initials Example' | translate }}
        </label>
        <canvas
          #abbreviatedNameCanvas
          class="canvas-signature col-12"
          id="canvas-abbreviated-name"
          height="140"
          [class.selected]="selectedSignature === 'abbreviate'"
          (click)="onTextSignatureSelect('abbreviate')"
        >
        </canvas>
      </div>
    </div>
  </div>
  <div class="row justify-content-end px-2">
    <button class="btn p-btn mx-2"
    [ngStyle]="{'border-color':themeList?.header_table_color, 'color':themeList?.header_table_color}"
    (click)="onCancelClick()">
      {{ 'PROFILE.Cancel' | translate }}
    </button>
      <button appSetTheme type="button" class="btn p-btn mx-2"
    (click)="onConfirmClick()" >
    {{ 'PROFILE.Save Signature' | translate }}
  </button>
    
  </div>
</div>