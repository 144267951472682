import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent {
  icon = 'icon-tabler-chevron-left';
  width = '24';
  height = '24';
  color = '#00000';

  constructor() {}
}
